<template>
  <v-row dense>
    <v-col dense>
      <v-btn dense large dark color="program" text @click="funatras">
        <v-icon dark small color="program">mdi-arrow-left</v-icon>
        <pre>&nbsp;</pre>
        Atras
      </v-btn>
      <v-card class="mx-auto my-12" max-width="800">
        <v-card-title>
          <v-toolbar flat>
            <v-toolbar-title>Crea tu cuenta</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="1"> </v-col>
            <v-col dense cols="10">
              <v-row dense>
                <v-col dense cols="12"
                  ><v-form ref="form1" onSubmit="return false;">
                    <v-text-field
                      v-model="datos.razon_social"
                      label="Nombre o Razón Social"
                      outlined
                      small
                      :rules="[rules.required, rules.razon]"
                      dense
                    ></v-text-field></v-form
                ></v-col>
                <v-col dense cols="6"
                  ><v-form ref="form2" onSubmit="return false;">
                    <v-text-field
                      v-model="datos.correo"
                      label="Correo"
                      outlined
                      small
                      :rules="[rules.required, rules.email]"
                      dense
                    ></v-text-field></v-form
                ></v-col>
                <v-col dense cols="6"
                  ><v-form ref="form3" onSubmit="return false;">
                    <v-text-field
                      v-model="datos.telefono"
                      label="Telefono"
                      outlined
                      small
                      :rules="[rules.required, rules.tel]"
                      dense
                    ></v-text-field></v-form
                ></v-col>
                <v-col dense cols="6"
                  ><v-form ref="form4" onSubmit="return false;">
                    <v-text-field
                      v-model="datos.usuario"
                      label="Usuario"
                      outlined
                      small
                      :rules="specialrule"
                      dense
                      :color="colo"
                      @keydown.enter="handleFormSubmit"
                    >
                      <template v-slot:append>
                        <v-icon
                          :color="color"
                          title="Verificar usuario"
                          @click="handleFormSubmit"
                        >
                          {{ icon }}
                        </v-icon>
                      </template>
                    </v-text-field></v-form
                  ></v-col
                >
                <v-col dense cols="6"
                  ><v-form ref="form5" onSubmit="return false;">
                    <v-text-field
                      v-model="datos.pass"
                      :type="type"
                      label="Contraseña"
                      outlined
                      small
                      :rules="[rules.required]"
                      dense
                    >
                      <template v-slot:append>
                        <v-icon title="Ver/Ocultar" @click="hide()">
                          {{ ico }}
                        </v-icon>
                      </template></v-text-field
                    >
                  </v-form></v-col
                >
                <v-col dense cols="8"
                  ><v-form ref="form6" onSubmit="return false;">
                    <v-tooltip top v-model="show">
                      <template v-slot:activator="{}">
                        <v-checkbox
                          :rules="[rules.required]"
                          v-model="checkbox"
                        >
                          <template v-slot:label>
                            <div>
                              He leído y acepto el
                              <a
                                target="_blank"
                                href="https://contago.com.mx/archivos/Aviso_de_Privacidad.pdf"
                                @click.stop
                              >
                                aviso de privacidad.
                              </a>
                            </div>
                          </template>
                        </v-checkbox>
                      </template>
                      {{ mess }}
                    </v-tooltip>
                  </v-form></v-col
                >
                <v-col dense cols="4">
                  <v-img
                    src="@/assets/contago.png"
                    transition="scale-transition"
                    max-height="50"
                    max-width="300"
                    @click="contago"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="1"> </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dense large dark color="program" text @click="crearcuenta">
            Crear Cuenta
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      <v-dialog v-model="dialogIni" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Cargando
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogpor" max-width="350">
        <v-card>
          <v-card-title> </v-card-title>
          <v-card-text class="text-h6">
            <h5>¿Desea crear un usuario nuevo?</h5>
          </v-card-text>
          <v-card-actions>
            <v-btn color="program" text @click="dialogpor = false">
              <h4>Cancelar</h4>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="login">
              <h4>Aceptar</h4>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogsi" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Usuario creado con éxito</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialogsi = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogno" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Nombre de usuario no disponible</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialogno = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog500" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Problemas de conexión</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog500 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog404" max-width="350">
        <v-card>
          <v-card-title class="headline"> Error </v-card-title>
          <v-card-text class="text-h6">
            <h5>{{ Message }}</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog404 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    Message: "Internal Error",
    dialogIni: false,
    dialogsi: false,
    dialogno: false,
    dialogpor: false,
    dialog500: false,
    dialog404: false,
    checkbox: false,
    type: "password",
    ico: "mdi-eye-off",
    icon: "mdi-earth-arrow-right",
    color: "blue darken-4",
    colo: "program",
    mess: "",
    enabled: false,
    show: false,
    por: false,
    datos: {
      razon_social: "",
      correo: "",
      telefono: "",
      usuario: "",
      pass: "",
    },
    rules: {
      required: (value) => !!value || "Requerido",
      razon: (value) => (value || "").length <= 40 || "Máximo 40 caracteres",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Correo inválido";
      },
      tel: (value) => {
        const pattern = /^([0-9]{10}[0-9]*)$/;
        return pattern.test(value) || "Teléfono inválido";
      },
      clabe: (value) => {
        const pattern = /^[0-9]{18}$/;
        return pattern.test(value) || "Debe tener 18 caracteres";
      },
    },
  }),

  computed: {
    usuario() {
      return this.datos.usuario;
    },
    specialrule() {
      const rules = [];
      const ruler = (v) => !!v || "Requerido";
      rules.push(ruler);
      const pattern = /^[A-Za-z0-9]{1,40}$/;
      const rule2 = (value) => pattern.test(value) || "Caracteres invalidos";
      rules.push(rule2);
      const rule0 = (value) =>
        (value || "").length >= 6 || "Mínimo 6 caracteres";
      rules.push(rule0);
      const rule1 = (value) =>
        this.icon != "mdi-earth-arrow-right" || "Falta validar usuario";
      rules.push(rule1);
      const rule = (value) => this.icon != "mdi-close" || "Usuario inválido";
      rules.push(rule);
      return rules;
    },
  },
  watch: {
    usuario() {
      this.icon = "mdi-earth-arrow-right";
      this.color = "blue darken-4";
      this.colo = "program";
      this.mess = "";
    },
  },

  methods: {
    crearcuenta() {
      var a = this.$refs.form1.validate();
      var b = this.$refs.form2.validate();
      var c = this.$refs.form3.validate();
      var d = this.$refs.form4.validate();
      var e = this.$refs.form5.validate();
      var f = this.$refs.form6.validate();

      if (a && b && c && d && e && f) {
        this.dialogpor = true;
      }
    },

    login() {
      var vue = this;
      vue.dialogpor = false;
      vue.dialogIni = true;
      var Params = {
        razon_social: vue.datos.razon_social,
        correo: vue.datos.correo,
        telefono: vue.datos.telefono,
        usuario: vue.datos.usuario,
        pass: vue.datos.pass,
      };
      axios
        .post("api/Codi/CrearUsuario", Params)
        .then(function (response) {
          vue.dialogIni = false;
          vue.dialogsi = true;
          vue.clear();
        })
        .catch(function (error) {
          vue.dialogIni = false;
          vue.clear();
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },

    funatras() {
      this.$router.push({ name: "Home" });
      document.getElementById("todo").style.display = "none";
    },

    clear() {
      this.$refs.form1.reset();
      this.$refs.form2.reset();
      this.$refs.form3.reset();
      this.$refs.form4.reset();
      this.$refs.form5.reset();
      this.$refs.form6.reset();
    },

    handleFormSubmit() {
      var vue = this;
      if (vue.datos.usuario == "") {
        vue.$refs.form4.validate();
        return;
      }
      if (vue.datos.usuario.length < 6) {
        vue.$refs.form4.validate();
        return;
      }
      if (vue.icon == "mdi-check" || vue.icon == "mdi-close") {
        return;
      }
      var Params = {
        user: vue.datos.usuario,
      };
      axios
        .post("api/Codi/ChecarUsuario", Params)
        .then(function (response) {
          if (response.data.user == "no") {
            vue.boolno();
          } else {
            vue.boolyes();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    hide() {
      if (this.type == "password") {
        this.type = "";
        this.ico = "mdi-eye";
      } else {
        this.type = "password";
        this.ico = "mdi-eye-off";
      }
    },

    boolyes() {
      this.show = true;
      this.icon = "mdi-check";
      this.color = "success";
      this.colo = "success";
      this.mess = "Usuario Válido";
      this.$refs.form4.validate();
      setTimeout(() => {
        this.show = false;
        this.colo = "program";
      }, 2000);
    },
    boolno() {
      this.show = true;
      this.icon = "mdi-close";
      this.color = "error";
      this.colo = "error";
      this.mess = "Usuario Inválido";
      this.$refs.form4.validate();
      setTimeout(() => {
        this.show = false;
        this.colo = "program";
      }, 2000);
    },
    contago(){
      window.open("https://contago.com.mx");
    }
  },
};
</script>
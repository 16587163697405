<template>
  <v-row
    ><v-col>
      <v-toolbar flat>
        <v-icon>mdi-account-circle</v-icon>
        <v-divider class="mx-4" vertical></v-divider>
        <v-spacer>
          <v-toolbar-title>Mi Cuenta</v-toolbar-title>
        </v-spacer>
      </v-toolbar>
      <v-row dense>
        <v-col dense cols="1"></v-col>
        <v-col dense cols="10">
          <v-row dense>
            <v-col dense cols="12" sm="4">
              <v-text-field
                outlined
                dense
                readonly
                v-model="objeto.usuario"
                label="Usuario"
              ></v-text-field>
            </v-col>
            <v-col dense cols="12" sm="4">
              <v-text-field
                outlined
                dense
                readonly
                v-model="funtipo"
                label="Tipo"
              ></v-text-field>
            </v-col>
            <v-col dense cols="12" sm="4">
              <v-text-field
                outlined
                dense
                readonly
                v-model="objeto.estado"
                label="Estado"
              ></v-text-field>
            </v-col>
            <v-col dense cols="0" sm="1"></v-col>
            <v-col dense cols="12" sm="4">
              <v-btn
                block
                color="program"
                dark
                class="mb-2"
                @click="dialogcambiar = true"
              >
                Cambiar Contraseña
              </v-btn>
            </v-col>
            <v-col dense cols="0" sm="2"></v-col>
            <v-col dense cols="12" sm="4">
              <v-btn
                block
                color="program"
                dark
                class="mb-2"
                @click="dialogeliminar = true"
              >
                Eliminar Cuenta
              </v-btn></v-col
            >
            <v-col dense cols="0" sm="1"></v-col>
            <v-col align="center" justify="center" dense cols="12"
              ><br />
              <template v-if="objeto.datos == ''">
                <v-toolbar-title
                  >Datos del QR General
                  <h6>No disponible</h6></v-toolbar-title
                >
              </template>
              <template v-else>
                <v-toolbar-title
                  >Datos del QR General
                  <h6>Vigencia al {{ objeto.datos }}</h6></v-toolbar-title
                >
              </template>
            </v-col>
            <v-col cols="12" dense>
              <v-row dense>
                <v-col align="center" justify="center" cols="12" sm="6">
                  <v-text-field
                    outlined
                    dense
                    readonly
                    v-model="objeto.folio"
                    label="Folio"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    readonly
                    v-model="objeto.des"
                    label="Concepto"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    readonly
                    v-model="objeto.refe"
                    label="Referencia"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    readonly
                    v-model="objeto.idc"
                    label="Id"
                  ></v-text-field>
                </v-col>
                <v-col align="center" justify="center" cols="12" sm="6">
                  <template v-if="objeto.imagen != ''">
                    <img
                      style="
                        height: auto;
                        width: 100%;
                        object-fit: contain;
                        max-height: 35vh;
                      "
                      v-bind:src="'data:image/png;base64,' + objeto.imagen"
                    />
                  </template>
                  <template v-else>
                    <div style="height: 90px"></div>
                    <v-icon x-large>mdi-qrcode-scan</v-icon>
                    <p>QR no disponible</p>
                  </template>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" dense>
              <v-row dense>
                <v-col dense cols="0" sm="1"></v-col>
                <v-col dense cols="12" sm="4">
                  <v-btn
                    block
                    color="program"
                    dark
                    class="mb-2"
                    @click="dialognuevo = true"
                  >
                    Crear Nuevo
                  </v-btn>
                </v-col>
                <v-col dense cols="0" sm="2"></v-col>
                <v-col dense cols="12" sm="4">
                  <v-btn
                    block
                    color="program"
                    dark
                    :disabled="objeto.imagen == ''"
                    class="mb-2"
                    @click="imprimir"
                  >
                    Imprimir QR
                  </v-btn>
                </v-col>
                <v-col dense cols="0" sm="1"></v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col dense cols="1"></v-col>
      </v-row>
      <v-dialog v-model="dialogIni" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Cargando
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog500" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Problemas de conexión</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog500 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog404" max-width="350">
        <v-card>
          <v-card-title class="headline"> Error </v-card-title>
          <v-card-text class="text-h6">
            <h5>{{ Message }}</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog404 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogeliminar" max-width="350">
        <v-card>
          <v-card-title> </v-card-title>
          <v-card-text class="text-h6">
            <h5>¿Desea eliminar permanentemente su cuenta?</h5>
          </v-card-text>
          <v-card-actions>
            <v-btn color="program" text @click="dialogeliminar = false">
              <h4>Cancelar</h4>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="eliminar">
              <h4>Aceptar</h4>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogcambiar" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Cambiar contraseña</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-form ref="formx" onSubmit="return false;">
                    <v-text-field
                      :rules="[rules.required]"
                      v-model="passold"
                      label="Contraseña Anterior"
                      outlined
                      small
                      dense
                    ></v-text-field
                  ></v-form>
                </v-col>
                <v-col cols="12">
                  <v-form ref="formy" onSubmit="return false;">
                    <v-text-field
                      :rules="[rules.required]"
                      v-model="passnew"
                      label="Contraseña Nueva"
                      outlined
                      small
                      dense
                    ></v-text-field
                  ></v-form>
                </v-col>
                <v-col cols="12">
                  <v-form ref="formz" onSubmit="return false;">
                    <v-text-field
                      :rules="specialrule2"
                      v-model="passnew2"
                      label="Repetir Contraseña"
                      outlined
                      small
                      dense
                    ></v-text-field
                  ></v-form>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="closecambiar"> Cancelar </v-btn>
            <v-btn color="program" text @click="cambiar"> Guardar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogcam" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Contraseña cambiada con exito.</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialogcam = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogeli" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Cuenta eliminada con exito, se cerrara la sesión.</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="now"> Aceptar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialognuevo" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Nuevo QR General</span>
          </v-card-title>
          <v-card-text>
            <p>
              Si creas un nuevo QR General se eliminará el anterior. El QR
              eliminado se puede seguir pagando hasta que expire, pero ya no se
              podrá imprimir despues.
            </p>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-form ref="formnuevo" onSubmit="return false;">
                    <v-text-field
                      v-model="nuevo.descripcion"
                      label="Concepto"
                      outlined
                      :rules="[rules.required, rules.descripcion]"
                      dense
                    ></v-text-field>
                    <v-text-field
                      v-model="nuevo.referencia"
                      label="Referencia"
                      outlined
                      :rules="[rules.required, rules.ref]"
                      dense
                    ></v-text-field>
                  </v-form>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="closenuevo">
              Cancelar
            </v-btn>
            <v-btn color="program" text @click="newgen"> Guardar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    rules: {
      required: (value) => !!value || "Requerido",
      descripcion: (value) => {
        const pattern = /^[A-Za-z0-9\u0020]{1,40}$/;
        return pattern.test(value) || "Caracteres inválidos";
      },
      ref: (value) => {
        const pattern = /^[0-9]{1,7}$/;
        return pattern.test(value) || "Numero inválido";
      },
    },
    Message: "Internal Error",
    dialogIni: false,
    dialog500: false,
    dialog404: false,
    dialogeliminar: false,
    dialogcambiar: false,
    dialogeli: false,
    dialogcam: false,
    dialognuevo: false,
    passold: "",
    passnew: "",
    passnew2: "",
    objeto: {
      usuario: "",
      tipo: "",
      estado: "",
      folio: "",
      refe: "",
      des: "",
      idc: "",
      imagen: "",
      datos: "",
    },
    tamano: 300,
    nuevo: {
      descripcion: "",
      referencia: "",
    },
  }),

  computed: {
    specialrule2() {
      const rules = [];
      const ruler = (v) => !!v || "Requerido";
      rules.push(ruler);
      const rule2 = (value) =>
        value == this.passnew || "Contraseñas no coinciden";
      rules.push(rule2);
      return rules;
    },
    funtipo() {
      var a = "";
      if (this.objeto.tipo == "Root") {
        a = "Desarrollador";
      } else if (this.objeto.tipo == "Admin") {
        a = "Administrador";
      } else if (this.objeto.tipo == "Normal") {
        a = "Usuario";
      }
      return a;
    },
  },

  watch: {
    passnew(val) {
      this.$refs.formy.validate();
      this.$refs.formz.validate();
    },
  },

  methods: {
    initialize() {
      this.items = [{ usuario: "", pass: "", tipo: "", estado: "" }];
      this.cargartodo();
    },

    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },

    newgen() {
      var a = this.$refs.formnuevo.validate();
      if (a) {
        var vue = this;
        var Params = {
          usuario: vue.$store.state.usuario,
          empresa: vue.$store.state.empresa,
          descripcion: vue.nuevo.descripcion,
          referencia: vue.nuevo.referencia,
        };
        vue.closenuevo();
        vue.dialogIni = true;
        axios
          .post("api/Codi/Create_QR_General", Params)
          .then(function (response) {
            vue.dialogIni = false;
            vue.objeto = {
              usuario: "",
              tipo: "",
              estado: "",
              folio: "",
              refe: "",
              des: "",
              idc: "",
              imagen: "",
              datos: "",
            };
            vue.cargartodo();
          })
          .catch(function (error) {
            vue.dialogIni = false;
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
            vue.objeto = {
              usuario: "",
              tipo: "",
              estado: "",
              folio: "",
              refe: "",
              des: "",
              idc: "",
              imagen: "",
              datos: "",
            };
            vue.cargartodo();
          });
      }
    },

    imprimir() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        empresa: vue.$store.state.empresa,
        tam: vue.tamano,
      };
      axios
        .post("api/Codi/DescargarQR", Params)
        .then(function (response) {
          vue.print(response.data.imagen);
          vue.dialogIni = false;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },

    print(imagen) {
      var ventana = window.open("", "PRINT");
      ventana.document.write(imagen);
      ventana.document.close();
      ventana.focus();
      setTimeout(() => {
        ventana.print();
      }, 500);
    },

    cargartodo() {
      var vue = this;
      vue.dialogIni = true;
      if (vue.$store.state.empresa == null || vue.$store.state.empresa == "") {
        vue.reloadPage();
        return;
      }
      var Params = {
        empresa: vue.$store.state.empresa,
        usuario: vue.$store.state.usuario,
      };
      axios
        .post("api/Codi/ConsultaUserPropio", Params)
        .then(function (response) {
          vue.objeto = response.data;
          vue.dialogIni = false;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },

    closecambiar() {
      this.$refs.formx.reset();
      this.$refs.formy.reset();
      this.$refs.formz.reset();
      this.dialogcambiar = false;
    },

    cambiar() {
      var a = this.$refs.formx.validate();
      var b = this.$refs.formy.validate();
      var c = this.$refs.formz.validate();
      if (a && b && c) {
        var vue = this;
        vue.dialogIni = true;
        var Params = {
          usuario: vue.$store.state.usuario,
          empresa: vue.$store.state.empresa,
          passold: vue.passold,
          passnew: vue.passnew,
        };
        axios
          .post("api/Codi/PasswordUser", Params)
          .then(function (response) {
            vue.$store.dispatch("guardarpass", response.data.empresa);
            vue.dialogIni = false;
            vue.closecambiar();
            vue.dialogcam = true;
          })
          .catch(function (error) {
            vue.dialogIni = false;
            vue.closecambiar();
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      }
    },

    eliminar() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        usuario: vue.$store.state.usuario,
        empresa: vue.$store.state.empresa,
      };
      axios
        .post("api/Codi/DesactivaUser", Params)
        .then(function (response) {
          vue.dialogIni = false;
          vue.dialogeliminar = false;
          vue.dialogeli = true;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          vue.dialogeliminar = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },
    now() {
      this.dialogeli = false;
      this.$store.dispatch("appsalir");
    },
    closenuevo() {
      this.dialognuevo = false;
      this.$refs.formnuevo.reset();
    }
  },
  mounted() {
    this.$store.dispatch("autologinA");
    this.initialize();
  },
};
</script>
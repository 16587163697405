<template>
  <v-row
    ><v-col>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        disable-sort
        class="elevation-1"
        :footer-props="{ itemsPerPageText: 'Filas por página:' }"
        :items-per-page="-1"
      >
          <template v-slot:top>
                    <v-card-title>
            <v-toolbar flat>
              <v-icon>mdi-bank</v-icon>
              <v-divider class="mx-4" vertical></v-divider>
              <v-spacer>
                <v-toolbar-title>Cuentas</v-toolbar-title>
              </v-spacer>
              <v-divider class="mx-4" vertical></v-divider>
              <v-dialog v-model="dialog" persistent max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="program"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Nuevo
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Nuevo</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-form ref="formopt">
                        <v-row>
                          <v-col cols="6">
                            <v-select
                              :rules="[rules.required]"
                              :items="bancos"
                              v-model="banco"
                              label="Banco"
                              dense
                              small
                              outlined
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              :rules="[rules.required, rules.clabe]"
                              v-model="numero"
                              label="Cuenta CLABE"
                              dense
                              small
                              outlined
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="program" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn color="program" text @click="save"> Guardar </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog persistent v-model="dialogDelete" max-width="400px">
                <v-card>
                  <v-card-title></v-card-title>
                  <v-card-text class="text-h6">
                    <h5>¿Seguro que deseas eliminar este elemento?</h5>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="program" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn color="program" text @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog persistent v-model="dialogMethod" max-width="400px">
                <v-card>
                  <v-card-title></v-card-title>
                  <v-card-text class="text-h6">
                    <h5>
                      ¿Seguro que deseas hacer tu método de pago este elemento?
                    </h5>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="program" text @click="closeMethod"
                      >Cancelar</v-btn
                    >
                    <v-btn color="program" text @click="methodItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
                    </v-card-title>
          </template>
        <template v-slot:[`item.estado`]="{ item }">
          <template v-if="item.estado == 'Activo'">
            <v-icon dark color="success">mdi-check-circle-outline</v-icon
            >&nbsp;Activo
          </template>
          <template v-else-if="item.estado == 'En Revision'">
            <v-icon dark color="warning">mdi-sync</v-icon><span>&nbsp;</span>En
            Revision
          </template>
          <template v-else-if="item.estado == 'Rechazado'">
            <v-icon dark color="error">mdi-close</v-icon><span>&nbsp;</span>
            Rechazado
          </template>
        </template>
        <template v-slot:[`item.metodo`]="{ item }">
          <template v-if="item.metodo == '' && item.estado == 'Activo'">
            <v-icon title="Hacer metodo de pago" @click="methodItem(item)">
              mdi-cash-refund
            </v-icon>
          </template>
          <template v-if="item.metodo == 'default'">
            <v-icon title="Cuenta elegida" dark color="success"
              >mdi-check-circle-outline</v-icon
            >
          </template>
        </template>
        <template v-slot:[`item.tipo`]="{ item }">
          <template v-if="item.tipo == '3'"> Tarjeta de débito </template>
          <template v-else-if="item.tipo == '10'"> Teléfono celular </template>
          <template v-else-if="item.tipo == '40'"> Cuenta CLABE </template>
        </template>
        <template v-slot:[`item.id_cuenta`]="{ item }">
          {{ items.indexOf(item) + 1 }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <template v-if="item.estado == 'Activo'"> </template>
          <template
            v-else-if="
              item.estado == 'En Revision' || item.estado == 'Rechazado'
            "
          >
            <v-icon title="Eliminar" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </template>
        <template v-slot:no-data>
          <br />
          <p>Sin datos disponibles</p>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogIni" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Cargando
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog500" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Problemas de conexión</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog500 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog404" max-width="350">
        <v-card>
          <v-card-title class="headline"> Error </v-card-title>
          <v-card-text class="text-h6">
            <h5>{{ Message }}</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog404 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    rules: {
      required: (value) => !!value || "Requerido",
      clabe: (value) => {
        const pattern = /^[0-9]{18}$/;
        return pattern.test(value) || "Debe tener 18 caracteres";
      },
    },
    Message: "Internal Error",
    dialog: false,
    dialogDelete: false,
    dialogMethod: false,
    dialogIni: false,
    dialog500: false,
    dialog404: false,
    headers: [
      {
        text: "ID",
        align: "start",
        value: "id_cuenta",
      },
      { text: "Banco", value: "nombre_banco", align: "center" },
      { text: "Número de Cuenta", value: "numero_cuenta", align: "center" },
      { text: "Tipo", value: "tipo", align: "center" },
      { text: "Estado", value: "estado", align: "center" },
      { text: "Método de Pago", value: "metodo", align: "center" },
      { text: "Eliminar", value: "actions", align: "center", sortable: false },
    ],
    items: [],
    bancos: [],
    claves: [],
    banco: "",
    clave: "",
    numero: "",
    editedIndex: -1,
    editedItem: {
      id_cuenta: "",
      nombre_banco: "",
      clave_banco: "",
      numero_cuenta: "",
      tipo: "",
      estado: "",
      metodo: "",
    },
    defaultItem: {
      id_cuenta: "",
      nombre_banco: "",
      clave_banco: "",
      numero_cuenta: "",
      tipo: "",
      estado: "",
      metodo: "",
    },
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    banco(val) {
      this.clave = this.claves[this.bancos.indexOf(val)];
    },
  },

  methods: {
    initialize() {
      this.items = [];
      this.cargartodo();
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.id_objeto = item.id_cuenta;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.eliminar();
    },

    methodItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.id_objeto = item.id_cuenta;
      this.dialogMethod = true;
    },

    methodItemConfirm() {
      this.default();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.id_objeto = "";
        this.$refs.formopt.reset();
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.id_objeto = "";
        if (this.$refs.formopt) {
          this.$refs.formopt.reset();
        }
      });
    },
    closeMethod() {
      this.dialogMethod = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.id_objeto = "";
        if (this.$refs.formopt) {
          this.$refs.formopt.reset();
        }
      });
    },

    save() {
      if (this.$refs.formopt.validate()) {
        this.agregar();
      }
    },

    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },

    cargartodo() {
      var vue = this;
      vue.dialogIni = true;
      if (vue.$store.state.empresa == null || vue.$store.state.empresa == "") {
        vue.reloadPage();
        return;
      }
      var Params = {
        empresa: vue.$store.state.empresa,
      };
      axios
        .post("api/Codi/ConsultaDatos", Params)
        .then(function (response) {
          vue.items = response.data;
          vue.dialogIni = false;
          vue.cargarselect();
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },
    cargarselect() {
      var vue = this;
      axios
        .post("api/Codi/Instituciones")
        .then(function (response) {
          for (var i = 0; i < response.data.length; i++) {
            vue.bancos.push(response.data[i].nombreCorto);
            vue.claves.push(response.data[i].clave_institucion);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    agregar() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        nombre_banco: vue.banco,
        clave_banco: vue.clave,
        numero_cuenta: vue.numero,
        tipo: "40",
        empresa: vue.$store.state.empresa,
      };
      axios
        .post("api/Codi/InsertaDatos", Params)
        .then(function (response) {
          vue.items = response.data;
          vue.dialogIni = false;
          vue.close();
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
          vue.close();
        });
    },

    eliminar() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        id_cuenta: vue.editedItem.id_cuenta,
        empresa: vue.$store.state.empresa,
      };
      axios
        .post("api/Codi/EliminaDatos", Params)
        .then(function (response) {
          vue.items = response.data;
          vue.dialogIni = false;
          vue.closeDelete();
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            vue.items = [];
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
          vue.closeDelete();
        });
    },

    default() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        id_cuenta: vue.editedItem.id_cuenta,
        empresa: vue.$store.state.empresa,
      };
      axios
        .post("api/Codi/MetodoDatos", Params)
        .then(function (response) {
          vue.items = response.data;
          vue.dialogIni = false;
          vue.closeMethod();
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            vue.items = [];
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
          vue.closeMethod();
        });
    },
  },
  mounted() {
    this.$store.dispatch("autologinB");
    this.initialize();
  },
};
</script>
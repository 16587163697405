<template>
  <v-row
    ><v-col>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        disable-sort
        class="elevation-1"
        :footer-props="{ itemsPerPageText: 'Filas por página:' }"
        :items-per-page="-1"
      >
        <template v-slot:top>
          <v-card-title>
            <v-toolbar flat>
              <v-icon>mdi-account-group</v-icon>
              <v-divider class="mx-4" vertical></v-divider>
              <v-spacer>
                <v-toolbar-title>Usuarios</v-toolbar-title>
              </v-spacer>
              <v-divider class="mx-4" vertical></v-divider>
              <v-dialog v-model="dialog" persistent max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="program"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Nuevo
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="6">
                          <v-form ref="form1" onSubmit="return false;">
                            <v-tooltip bottom v-model="show">
                              <template v-slot:activator="{}">
                                <v-text-field
                                  :prefix="usu"
                                  v-model="editedItem.usuario"
                                  label="Usuario"
                                  outlined
                                  small
                                  :rules="specialrule"
                                  dense
                                  :color="colo"
                                  @keydown.enter="handleFormSubmit"
                                >
                                  <template v-slot:append>
                                    <v-icon
                                      :color="color"
                                      title="Verificar usuario"
                                      @click="handleFormSubmit"
                                    >
                                      {{ icon }}
                                    </v-icon>
                                  </template>
                                </v-text-field>
                              </template>
                              {{ mess }}
                            </v-tooltip>
                          </v-form>
                        </v-col>
                        <v-col cols="6">
                          <v-form ref="form2" onSubmit="return false;">
                            <v-text-field
                              :rules="[rules.required]"
                              v-model="editedItem.pass"
                              label="Contraseña"
                              outlined
                              small
                              dense
                            ></v-text-field
                          ></v-form>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="program" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn color="program" text @click="save"> Guardar </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog persistent v-model="dialogDelete" max-width="400px">
                <v-card>
                  <v-card-title></v-card-title>
                  <v-card-text class="text-h6">
                    <h5>¿Seguro que deseas eliminar este elemento?</h5>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="program" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn color="program" text @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </v-card-title>
        </template>
        <template v-slot:[`item.id_usuario`]="{ item }">
          {{ items.indexOf(item) + 1 }}
        </template>
        <template v-slot:[`item.pass`]="{ item }">
          <template v-if="item.tipo == 'Admin' || item.tipo == 'Root'">
            {{ "•".repeat(item.pass.length) }}
          </template>
          <template v-else>
            {{ item.pass }}
          </template>
        </template>
        <template v-slot:[`item.tipo`]="{ item }">
          <template v-if="item.tipo == 'Root'"> Desarrollador </template>
          <template v-else-if="item.tipo == 'Admin'"> Administrador </template>
          <template v-else> Usuario </template>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <template v-if="item.tipo == 'Admin' || item.tipo == 'Root'">
          </template>
          <template v-else>
            <v-icon title="Eliminar" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </template>
        <template v-slot:no-data>
          <br />
          <p>Sin datos disponibles</p>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogIni" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Cargando
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog500" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Problemas de conexión</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog500 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog404" max-width="350">
        <v-card>
          <v-card-title class="headline"> Error </v-card-title>
          <v-card-text class="text-h6">
            <h5>{{ Message }}</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog404 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    rules: {
      required: (value) => !!value || "Requerido",
      clabe: (value) => {
        const pattern = /^[0-9]{18}$/;
        return pattern.test(value) || "Debe tener 18 caracteres";
      },
    },
    Message: "Internal Error",
    icon: "mdi-earth-arrow-right",
    color: "blue darken-4",
    colo: "program",
    mess: "",
    dialog: false,
    dialogDelete: false,
    dialogIni: false,
    dialog500: false,
    dialog404: false,
    show: false,
    headers: [
      {
        text: "ID",
        align: "start",
        value: "id_usuario",
      },
      { text: "Usuario", value: "usuario", align: "center" },
      { text: "Contraseña", value: "pass", align: "center" },
      { text: "Tipo", value: "tipo", align: "center" },
      { text: "Estado", value: "estado", align: "center" },
      {
        text: "Editar/Eliminar",
        value: "actions",
        align: "center",
        sortable: false,
      },
    ],
    items: [],
    editedIndex: -1,
    editedItem: {
      id_usuario: "",
      usuario: "",
      pass: "",
      tipo: "",
      estado: "",
    },
    defaultItem: {
      id_usuario: "",
      usuario: "",
      pass: "",
      tipo: "",
      estado: "",
    },
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    bas() {
      this.icon = "mdi-earth-arrow-right";
      this.color = "blue darken-4";
      this.colo = "program";
      this.mess = "";
      this.show = false;
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo" : "Editar";
    },
    specialrule() {
      const rules = [];
      const ruler = (v) => !!v || "Requerido";
      rules.push(ruler);
      const pattern = /^[A-Za-z0-9]{1,40}$/;
      const rule2 = (value) => pattern.test(value) || "Caracteres invalidos";
      rules.push(rule2);
      const rule0 = (value) =>
        (value || "").length >= 4 || "Mínimo 4 caracteres";
      rules.push(rule0);
      const rule1 = (value) =>
        this.icon != "mdi-earth-arrow-right" || "Falta validar usuario";
      rules.push(rule1);
      const rule = (value) => this.icon != "mdi-close" || "Usuario inválido";
      rules.push(rule);
      return rules;
    },
    bas() {
      return this.editedItem.usuario;
    },
    usu() {
      var a = this.$store.state.empresa.replace("codi_", "");
      return a + "_";
    },
  },

  methods: {
    initialize() {
      this.items = [];
      this.cargartodo();
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.eliminar();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.$refs.form1.reset();
        this.$refs.form2.reset();
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        if (this.$refs.form1) {
          this.$refs.form1.reset();
        }
        if (this.$refs.form2) {
          this.$refs.form2.reset();
        }
      });
    },

    save() {
      var a = this.$refs.form1.validate();
      var b = this.$refs.form2.validate();
      if (a && b) {
        if (this.editedIndex > -1) {
          //Object.assign(this.items[this.editedIndex], this.editedItem);
          this.editar();
        } else {
          //this.items.push(this.editedItem);
          this.agregar();
        }
      }
    },

    handleFormSubmit() {
      var vue = this;
      if (vue.editedItem.usuario == "") {
        vue.$refs.form1.validate();
        return;
      }
      if (vue.editedItem.usuario.length < 4) {
        vue.$refs.form1.validate();
        return;
      }
      if (vue.icon == "mdi-check" || vue.icon == "mdi-close") {
        return;
      }
      var Params = {
        user: vue.usu + vue.editedItem.usuario,
      };
      axios
        .post("api/Codi/ChecarUsuario", Params)
        .then(function (response) {
          if (response.data.user == "no") {
            vue.boolno();
          } else {
            vue.boolyes();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    boolyes() {
      this.show = true;
      this.icon = "mdi-check";
      this.color = "success";
      this.colo = "success";
      this.mess = "Usuario Válido";
      this.$refs.form1.validate();
      setTimeout(() => {
        this.show = false;
        this.colo = "program";
      }, 2000);
    },
    boolno() {
      this.show = true;
      this.icon = "mdi-close";
      this.color = "error";
      this.colo = "error";
      this.mess = "Usuario Inválido";
      this.$refs.form1.validate();
      setTimeout(() => {
        this.show = false;
        this.colo = "program";
      }, 2000);
    },

    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },

    cargartodo() {
      var vue = this;
      vue.dialogIni = true;
      if (vue.$store.state.empresa == null || vue.$store.state.empresa == "") {
        vue.reloadPage();
        return;
      }
      var Params = {
        empresa: vue.$store.state.empresa,
      };
      axios
        .post("api/Codi/ConsultaUser", Params)
        .then(function (response) {
          vue.items = response.data;
          vue.dialogIni = false;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },

    agregar() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        usuario: vue.usu + vue.editedItem.usuario,
        pass: vue.editedItem.pass,
        empresa: vue.$store.state.empresa,
      };
      axios
        .post("api/Codi/CrearUser", Params)
        .then(function (response) {
          vue.items = response.data;
          vue.dialogIni = false;
          vue.close();
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
          vue.close();
        });
    },

    editar() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        id_usuario: vue.editedItem.id_usuario,
        usuario: vue.editedItem.usuario,
        pass: vue.editedItem.pass,
        empresa: vue.$store.state.empresa,
      };
      axios
        .post("api/Codi/ActualizarUser", Params)
        .then(function (response) {
          vue.items = response.data;
          vue.dialogIni = false;
          vue.close();
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
          vue.close();
        });
    },

    eliminar() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        id_usuario: vue.editedItem.id_usuario,
        empresa: vue.$store.state.empresa,
      };
      axios
        .post("api/Codi/EliminarUser", Params)
        .then(function (response) {
          vue.items = response.data;
          vue.dialogIni = false;
          vue.closeDelete();
        })
        .catch(function (error) {
          vue.dialogIni = false;
          vue.dialogIni = false;
          if (error.response) {
            vue.items = [];
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
          vue.closeDelete();
        });
    },
  },
  mounted() {
    this.$store.dispatch("autologinB");
    this.initialize();
  },
};
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Crear_Cuenta from '../components/Crear_Cuenta'
import Codi from '../components/Codi'
import Cuentas from '../components/Cuentas'
import Datos from '../components/Datos'
import Usuarios from '../components/Usuarios'
import Solicitudes from '../components/Solicitudes'
import Historial from '../components/Historial'
import MiCuenta from '../components/MiCuenta'
import NotFound from '../views/NotFound.vue'


Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/Codi',
        name: 'Codi',
        component: Codi
    },
    {
        path: '/Cuentas',
        name: 'Cuentas',
        component: Cuentas
    },
    {
        path: '/Datos',
        name: 'Datos',
        component: Datos
    },
    {
        path: '/Crear_Cuenta',
        name: 'Crear_Cuenta',
        component: Crear_Cuenta
    },
    {
        path: '/Usuarios',
        name: 'Usuarios',
        component: Usuarios
    },
    {
        path: '/Solicitudes',
        name: 'Solicitudes',
        component: Solicitudes
    },
    {
        path: '/Historial',
        name: 'Historial',
        component: Historial
    },
    {
        path: '/MiCuenta',
        name: 'MiCuenta',
        component: MiCuenta
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFound
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


export default router
<template>
  <v-row>
    <v-col>
      <v-row>
        <v-btn large dark color="program" text @click="funcreate">
          <v-icon dark small color="program">mdi-account-plus</v-icon>
          <pre>&nbsp;</pre>
          Crear Cuenta
        </v-btn>
        <v-spacer />        
        <v-btn large dark color="program" text @click="contago">
          <v-icon dark small color="program">mdi-phone</v-icon>
          <pre>&nbsp;</pre>
        Contáctanos
        </v-btn>
      </v-row>
      <v-card class="mx-auto my-12" max-width="400">
        <br />
        <v-row>
          <v-spacer></v-spacer>
          <v-img
            src="@/assets/contago.png"
            transition="scale-transition"
            max-height="200"
            max-width="300"
          />
          <v-img
            src="@/assets/contagobw.png"
            transition="scale-transition"
            max-height="0"
            max-width="0"
          />
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-col cols="2"> </v-col>
          <v-col cols="8">
            <v-form ref="form1" onSubmit="return false;">
              <v-text-field
                v-model="datos.user"
                label="Usuario"
                outlined
                small
                :rules="[rules.required]"
                dense
                @keydown.enter="login"
              ></v-text-field>
            </v-form>
            <v-form ref="form2" onSubmit="return false;">
              <v-text-field
                v-model="datos.pass"
                type="password"
                label="Contraseña"
                outlined
                small
                :rules="[rules.required]"
                dense
                @keydown.enter="login"
              ></v-text-field>
            </v-form>
            <template v-if="disabled">
              <v-row>
                <v-spacer></v-spacer>
                <v-btn id="g1" large dark color="program" text @click="login">
                  Iniciar Sesión
                </v-btn>
                <v-spacer></v-spacer>
              </v-row>
            </template>
          </v-col>
          <v-col cols="2"> </v-col>
        </v-row>
      </v-card>
      <v-dialog v-model="dialogIni" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Iniciando Sesión
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog500" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Problemas de conexión</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog500 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog404" max-width="350">
        <v-card>
          <v-card-title class="headline"> Error </v-card-title>
          <v-card-text class="text-h6">
            <h5>{{ Message }}</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog404 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>


<script>
import axios from "axios";
export default {
  data: () => ({
    Message: "Internal Error",
    disabled: true,
    dialogIni: false,
    dialog500: false,
    dialog404: false,
    datos: {
      user: "",
      pass: "",
    },
    rules: {
      required: (value) => !!value || "Requerido",
    },
  }),
  methods: {
    login() {
      var vue = this;
      var a = this.$refs.form1.validate();
      var b = this.$refs.form2.validate();
      if (a && b) {
        vue.dialogIni = true;
        var Params = {
          user: vue.datos.user,
          pass: vue.datos.pass,
        };
        axios
          .post("api/Codi/LoginUsuariosIni", Params)
          .then(function (response) {
            vue.dialogIni = false;
            vue.$store.dispatch("guardartoken", response.data.token);
            vue.$store.dispatch("guardarusuario", response.data.user);
            vue.$store.dispatch("guardarpass", response.data.pass);
            vue.$store.dispatch("guardarempresa", response.data.empresa);
            vue.$store.dispatch("guardarnombre", response.data.nombre);
            document.getElementById("todo").style.display = "block";
            if (response.data.empresa == "") {
              vue.$router.push({ name: "Datos" });
            } else {
              vue.$router.push({ name: "Codi" });
            }
          })
          .catch(function (error) {
            vue.dialogIni = false;
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
            vue.$store.dispatch("guardartoken", "");
            vue.$store.dispatch("guardarusuario", "");
            vue.$store.dispatch("guardarpass", "");
            vue.$store.dispatch("guardarempresa", "");
            vue.$store.dispatch("guardarnombre", "");
            vue.$refs.form1.reset();
            vue.$refs.form2.reset();
          });
      }
    },
    funcreate() {
      this.$router.push({ name: "Crear_Cuenta" });
      document.getElementById("todo").style.display = "none";
    },
    contago() {
      window.open("https://contago.com.mx/codi");
    },
  },
  mounted() {
    this.$store.dispatch("homelogin");
  },
};
</script>
<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-toolbar flat>
              <v-icon>mdi-qrcode</v-icon>
              <v-divider class="mx-4" vertical></v-divider>
              <v-toolbar-title>CoDi</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="2"></v-col>
                <v-col cols="8">
                  <v-form ref="form1" onSubmit="return false;">
                    <v-text-field
                      v-model="descripcion"
                      label="Concepto"
                      outlined
                      :rules="[rules.required, rules.descripcion]"
                      dense
                      @keydown.enter="blurAll"
                    ></v-text-field>
                    <v-text-field
                      v-model="referencia"
                      label="Referencia"
                      outlined
                      :rules="[rules.required, rules.ref]"
                      dense
                      @keydown.enter="blurAll"
                    ></v-text-field>
                  </v-form>
                  <v-form ref="form2" onSubmit="return false;">
                    <v-text-field
                      prefix="$"
                      v-model="monto"
                      label="Importe"
                      outlined
                      :rules="specialrule"
                      dense
                      @keydown.enter="blurAll"
                    ></v-text-field>
                  </v-form>
                  <v-radio-group dense v-model="row" row mandatory>
                    <v-radio label="Sin Propina" value="radio-0"></v-radio>
                    <v-radio label="10%" value="radio-10"></v-radio>
                    <v-radio label="15%" value="radio-15"></v-radio>
                    <v-radio label="Otro" value="radio-1"></v-radio>
                    <v-spacer></v-spacer>
                    <v-btn
                      small
                      large
                      dark
                      text
                      color="program"
                      :rules="rules"
                      @click="clear"
                    >
                      Reiniciar
                    </v-btn>
                  </v-radio-group>
                  <v-form ref="form3" onSubmit="return false;">
                    <template v-if="row != 'radio-0'">
                      <v-text-field
                        prefix="$"
                        v-model="propina"
                        label="Propina"
                        outlined
                        :rules="specialrule"
                        :disabled="row == 'radio-0'"
                        :readonly="row != 'radio-1'"
                        dense
                        @keydown.enter="blurAll"
                      ></v-text-field>
                      <v-text-field
                        prefix="$"
                        v-model="total"
                        label="Total"
                        outlined
                        :disabled="row == 'radio-0'"
                        :rules="specialrule"
                        readonly
                        dense
                        @keydown.enter="blurAll"
                      ></v-text-field>
                    </template>
                  </v-form>
                </v-col>
                <v-col cols="2"></v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-row dense>
            <v-col dense cols="1"></v-col>
            <v-col dense cols="10">
              <v-row dense>
                <v-col dense cols="0" sm="4"> </v-col>
                <v-col dense cols="12" sm="4">
                  <v-btn
                    dense
                    title="Generar Codigo QR"
                    small
                    large
                    dark
                    block
                    color="program"
                    @click="move"
                  >
                    QR
                    <pre>&nbsp;</pre>
                    <v-icon>mdi-qrcode</v-icon>
                  </v-btn>
                </v-col>
                <v-col dense cols="12" sm="4">
                  <v-btn
                    dense
                    title="Enviar Notificación a celular"
                    small
                    large
                    dark
                    block
                    color="program"
                    @click="telef"
                  >
                    Enviar
                    <pre>&nbsp;</pre>
                    <v-icon>mdi-notification-clear-all</v-icon>
                  </v-btn>
                </v-col>
                <!-- <v-col dense cols="12" sm="4"> </v-col>
                <v-col dense cols="12" sm="8">
                  <v-btn
                    dense
                    title="Imprimir Codigo QR General"
                    small
                    large
                    dark
                    block
                    color="program"
                    @click="dialogGeneral = true"
                  >
                    QR General Sin Monto
                    <pre>&nbsp;</pre>
                    <v-icon>mdi-information</v-icon>
                  </v-btn>
                </v-col> -->
              </v-row>
            </v-col>
            <v-col cols="1"></v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-dialog v-model="dialogsi" persistent max-width="400">
        <v-card>
          <v-card-text>
            <br />
            <h2>
              Pago Realizado&nbsp;<v-icon color="success">mdi-check</v-icon>
            </h2>
          </v-card-text>
          <v-card-actions>
            <v-btn icon dense large dark color="success" text @click="imprimir">
              <v-icon title="Imprimir">mdi-printer</v-icon>
            </v-btn>
            <v-btn icon dense large dark color="success" text @click="imagen">
              <v-icon title="Descargar imagen">mdi-image</v-icon>
            </v-btn>
            <v-btn
              icon
              dense
              large
              dark
              color="success"
              text
              @click="dialogA = true"
            >
              <v-icon title="Enviar por correo">mdi-email</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn dark color="success" text @click="dialogsi = false">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog" hide-overlay persistent width="300">
        <v-card color="program" dark>
          <v-card-text>
            Cargando
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogcodi" persistent max-width="600px" scrollable>
        <v-card>
          <v-card-title dense>
            <template v-if="color == 'indigo darken-4'">
              <v-menu
                bottom
                v-model="menu"
                :close-on-content-click="false"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    block
                    v-bind="attrs"
                    v-on="on"
                    @click="menu = true"
                    text
                    dark
                    color="indigo darken-4"
                  >
                    <v-icon large dark>mdi-alert-circle-outline</v-icon>
                    <pre>&nbsp;</pre>
                    Pagos Multiples
                  </v-btn>
                </template>
                <v-card max-height="100">
                  <v-list>
                    <v-list-item>
                      <span style="white-space: pre-line"
                        ><h4>{{ label }}</h4></span
                      >
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </template>
            <template v-else>
              <v-btn block text dark :color="color">
                <v-icon large dark>{{ icon }}</v-icon>
                <pre>&nbsp;</pre>
                {{ label }}
              </v-btn>
            </template>
          </v-card-title>
          <v-card-text dense style="height: 500px">
            <div
              style="height: 100%; width: 100%; object-fit: contain"
              class="d-flex flex-column justify-space-between align-center"
            >
              <img
                style="height: 100%; width: 100%; object-fit: contain"
                v-bind:src="'data:image/jpeg;base64,' + imagefinal"
              />
            </div>
          </v-card-text>
          <v-card-actions dense>
            <v-btn large dark color="program" text @click="update">
              Verificar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn icon dense large dark color="program" text @click="impcodi">
              <v-icon title="Imprimir">mdi-printer</v-icon>
            </v-btn>
            <v-btn
              icon
              dense
              large
              dark
              color="program"
              text
              @click="imagencodi"
            >
              <v-icon title="Descargar imagen">mdi-image</v-icon>
            </v-btn>
            <v-btn
              icon
              dense
              large
              dark
              color="program"
              text
              @click="dialogAA = true"
            >
              <v-icon title="Enviar por correo">mdi-email</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn large dark color="program" text @click="close">
              Cerrar
            </v-btn>
          </v-card-actions>
          <template v-if="countDown > 0">
            <v-progress-linear
              indeterminate
              color="grey darken-1"
            ></v-progress-linear>
          </template>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogpush" persistent max-width="600px" scrollable>
        <v-card>
          <v-card-title dense>
            <template v-if="color == 'indigo darken-4'">
              <v-menu
                bottom
                v-model="menu"
                :close-on-content-click="false"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    block
                    v-bind="attrs"
                    v-on="on"
                    @click="menu = true"
                    text
                    dark
                    color="indigo darken-4"
                  >
                    <v-icon large dark>mdi-alert-circle-outline</v-icon>
                    <pre>&nbsp;</pre>
                    Pagos Multiples
                  </v-btn>
                </template>
                <v-card max-height="100">
                  <v-list>
                    <v-list-item>
                      <span style="white-space: pre-line"
                        ><h4>{{ label }}</h4></span
                      >
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </template>
            <template v-else>
              <v-btn block text dark :color="color">
                <v-icon large dark>{{ icon }}</v-icon>
                <pre>&nbsp;</pre>
                {{ label }}
              </v-btn>
            </template>
          </v-card-title>
          <v-card-text dense style="height: 60px">
            <div
              style="height: 100%; width: 100%; object-fit: contain"
              class="d-flex flex-column justify-space-between align-center"
            >
              <br />
              <h1>NOTIFICACION PUSH</h1>
            </div>
          </v-card-text>
          <v-card-actions dense>
            <v-btn large dark color="program" text @click="update">
              Verificar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn large dark color="program" text @click="close">
              Cerrar
            </v-btn>
          </v-card-actions>
          <template v-if="countDown > 0">
            <v-progress-linear
              indeterminate
              color="grey darken-1"
            ></v-progress-linear>
          </template>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog500" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Problemas de conexión</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog500 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog404" max-width="350">
        <v-card>
          <v-card-title class="headline"> Error </v-card-title>
          <v-card-text class="text-h6">
            <h5>{{ Message }}</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog404 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogA" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Enviar un correo</h5>
            <br />
            <v-form ref="formA" onSubmit="return false;">
              <v-text-field
                v-model="correo"
                label="Correo"
                outlined
                small
                :rules="[rules.required, rules.email]"
                dense
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="program" text @click="cerrarA"> Cancelar </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="funcorreo"> Aceptar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogB" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Correo enviado correctamente</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialogB = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogAA" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Enviar un correo</h5>
            <br />
            <v-form ref="formAA" onSubmit="return false;">
              <v-text-field
                v-model="correoo"
                label="Correo"
                outlined
                small
                :rules="[rules.required, rules.email]"
                dense
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="program" text @click="cerrarAA"> Cancelar </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="correocodi"> Aceptar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogBB" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Correo enviado correctamente</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialogBB = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogC" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Enviar un teléfono</h5>
            <br />
            <v-form ref="formCC" onSubmit="return false;">
              <v-text-field
                v-model="telefonoo"
                label="Teléfono"
                outlined
                small
                :rules="[rules.required, rules.tel]"
                dense
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="program" text @click="cerrarC"> Cancelar </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="send"> Aceptar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogGeneral" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Elige un tamaño de QR y letra</h5>
            <br />
            <v-form ref="formgen" onSubmit="return false;">
              <v-text-field
                v-model="tam"
                label="Tamaño QR"
                type="number"
                :step="10"
                outlined
                small
                :rules="[rules.required, rules.qr]"
                dense
              ></v-text-field>
            </v-form>
            <v-form ref="formfont" onSubmit="return false;">
              <v-text-field
                v-model="font"
                label="Tamaño letra"
                type="number"
                :step="1"
                outlined
                small
                :rules="[rules.required, rules.font]"
                dense
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="program" text @click="cerrargen"> Cancelar </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="movegen"> Aceptar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div class="under" id="insertar"></div>
    </v-row>
  </div>
</template>

<style>
.under {
  z-index: -10;
  padding: 0;
  margin: 0;
}
</style>

<script>
import axios from "axios";
import moment from "moment";
import { format, parseISO } from "date-fns";
import html2canvas from "html2canvas";
import print from "print-js";
import pdf from "vue-pdf";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    print,
    VueEditor,
    pdf: pdf,
  },
  data: () => ({
    Message: "",
    Montoper: "Sin Importe",
    pref: "",
    countDown: 0,
    icono: "",
    pagado: true,
    imagefinal: "",
    descripcion: "Venta",
    monto: "",
    propina: "0.00",
    total: "",
    referencia: format(parseISO(new Date().toISOString()), "SSSssmm"),
    idc: "",
    label: "Codi Creado",
    color: "black",
    icon: "mdi-qrcode",
    respuesta: {},
    lista: [],
    dialog: false,
    menu: false,
    dialogcodi: false,
    dialogpush: false,
    dialogsi: false,
    dialog404: false,
    dialog500: false,
    dialogA: false,
    dialogB: false,
    dialogGeneral: false,
    correo: "",
    dialogAA: false,
    dialogBB: false,
    dialogC: false,
    correoo: "",
    telefonoo: "",
    tam: "300",
    font: "25",
    row: null,
    hecho: null,
    rules: {
      required: (value) => !!value || "Requerido",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Correo inválido";
      },
      descripcion: (value) => {
        const pattern = /^[A-Za-z0-9\u0020]{1,40}$/;
        return pattern.test(value) || "Caracteres inválidos";
      },
      ref: (value) => {
        const pattern = /^[0-9]{1,7}$/;
        return pattern.test(value) || "Numero inválido";
      },
      tel: (value) => {
        const pattern = /^[0-9]{10}$/;
        return pattern.test(value) || "Teléfono a 10 dígitos inválido";
      },
      qr: (value) => !(value < 50 || value > 600) || "Entre 50 y 600",
      font: (value) => !(value < 8 || value > 40) || "Entre 8 y 40",
    },
    f401: "Codi Creado",
    f400: "Error (",
    f1: "Esperando Pago",
    f0: "Estado tipo (",
    e0: "Procesando Pago",
    e1: "Pago Realizado",
    e2: "Pago Rechazado",
    e3: "Pago No Realizado",
    e4: "Pago Pospuesto",
    e6: "Pago Devuelto",
    e21: "Pago Cancelado (21)",
    e22: "Pago Cancelado (22)",
    e23: "Pago Cancelado (23)",
    e24: "Pago Cancelado (24)",
    e31: "Pago por Devolver (31)",
    e32: "Pago por Devolver (32)",
    e33: "Pago por Devolver (33)",
    e34: "Procesando Devolución (34)",
    e61: "Procesando Devolución (61)",
    e62: "Devolución No Procesada (62)",
    e63: "Devolución No Procesada (63)",
  }),

  computed: {
    specialrule() {
      const rules = [];
      const ruler = (v) => !!v || "Requerido";
      rules.push(ruler);
      const pattern =
        /^([1-9][0-9]*\.[0-9][0-9]|[0]\.[0-9][1-9]|[0]\.[1-9][0-9])$/;
      const rule = (value) => pattern.test(value) || "Invalido, ejemplo: 0.01";
      rules.push(rule);
      const rulem = (value) =>
        parseFloat(value) <= 8000 || "Monto máximo de 8,000.00";
      rules.push(rulem);
      return rules;
    },
  },

  watch: {
    row(val) {
      if (val == "radio-10") {
        if (this.$refs.form2.validate()) {
          this.propina = (parseFloat(this.monto) * 0.1).toFixed(2);
        } else {
          this.row = null;
        }
      } else if (val == "radio-15") {
        if (this.$refs.form2.validate()) {
          this.propina = (parseFloat(this.monto) * 0.15).toFixed(2);
        } else {
          this.row = null;
        }
      } else if (val == "radio-1") {
        if (this.$refs.form2.validate()) {
        } else {
          this.row = null;
        }
      } else if (val == "radio-0") {
        this.propina = "0.00";
        this.$refs.form3.reset();
      }
    },

    propina(val) {
      this.total = (parseFloat(this.monto) + parseFloat(this.propina)).toFixed(
        2
      );
    },

    monto(val) {
      this.total = (parseFloat(this.monto) + parseFloat(this.propina)).toFixed(
        2
      );
      this.row = null;
    },
  },

  methods: {
    move() {
      var a = this.$refs.form1.validate();
      var b = this.$refs.form2.validate();
      var c = this.$refs.form3.validate();
      if (a && b && c) {
        let vue = this;
        vue.imagefinal = "";
        var prop = "";
        if (vue.propina == null || vue.propina == "") {
          prop = "0.00";
        } else {
          prop = vue.propina;
        }
        var Param = {
          usuario: vue.$store.state.usuario,
          descripcion: vue.descripcion,
          monto: vue.monto,
          propina: prop,
          referencia: vue.referencia,
          empresa: vue.$store.state.empresa,
        };
        vue.dialog = true;
        axios
          .post("api/Codi/Create_QR", Param)
          .then(function (response) {
            vue.dialog = false;

            vue.imagefinal = response.data.imagen;
            vue.idc = response.data.idc;
            vue.dialogcodi = true;
            vue.countDown = 120;
            vue.label = vue.f401;
            vue.countDownTimer();
          })
          .catch(function (error) {
            vue.dialog = false;

            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      }
    },

    movegen() {
      var a = this.$refs.form1.validate();
      if (a) {
        let vue = this;
        var Param = {
          usuario: vue.$store.state.usuario,
          descripcion: "General " + vue.descripcion,
          referencia: vue.referencia,
          empresa: vue.$store.state.empresa,
          tam: vue.tam,
          font: vue.font,
        };
        vue.dialog = true;
        axios
          .post("api/Codi/Create_QR_General", Param)
          .then(function (response) {
            vue.dialog = false;
            vue.cerrargen();
            vue.print(response.data.imagen, 1500);
          })
          .catch(function (error) {
            vue.dialog = false;
            vue.cerrargen();
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      }
    },

    print(imagen, time) {
      var ventana = window.open("", "PRINT");
      ventana.document.write(imagen);
      ventana.document.close();
      ventana.focus();
      setTimeout(() => {
        ventana.print();
      }, time);
    },

    send() {
      var d = this.$refs.formCC.validate();
      if (d) {
        let vue = this;
        vue.imagefinal = "";
        var prop = "";
        if (vue.propina == null || vue.propina == "") {
          prop = "0.00";
        } else {
          prop = vue.propina;
        }
        var Param = {
          usuario: vue.$store.state.usuario,
          celular: vue.telefonoo,
          monto: vue.monto,
          propina: prop,
          descripcion: vue.descripcion,
          referencia: vue.referencia,
          empresa: vue.$store.state.empresa,
        };
        vue.dialog = true;
        axios
          .post("api/Codi/Create_PUSH", Param)
          .then(function (response) {
            vue.telefonoo = "";
            vue.$refs.formCC.resetValidation();
            vue.dialog = false;
            vue.dialogC = false;

            vue.imagefinal = "";
            vue.idc = response.data.idc;
            vue.dialogpush = true;
            vue.countDown = 120;
            vue.label = vue.f401;
            vue.countDownTimer();
          })
          .catch(function (error) {
            vue.telefonoo = "";
            vue.$refs.formCC.resetValidation();
            vue.dialog = false;
            vue.dialogC = false;

            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      }
    },

    clear() {
      this.descripcion = "Venta";
      this.referencia = format(parseISO(new Date().toISOString()), "SSSssmm");
      this.monto = "";
      this.propina = "0.00";
      this.total = "";
      this.$refs.form1.resetValidation();
      this.$refs.form2.resetValidation();
      this.$refs.form3.resetValidation();
      this.row = null;
      this.imagefinal = "";
      this.idc = "";
      this.label = "Codigo Generado";
      this.color = "black";
      this.icon = "mdi-qrcode";
      this.hecho = null;
      this.countDown = 0;
      this.respuesta = {};
      this.dialogcodi = false;
      this.dialogpush = false;
    },

    close() {
      this.clear();
    },
    update() {
      let vue = this;
      var Param = {
        idc: vue.idc,
        empresa: vue.$store.state.empresa,
      };
      vue.lista = [];
      axios
        .post("api/Codi/Dec_Response", Param)
        .then(function (response) {
          if (response.data.length == 1) {
            if (response.data[0].e == "-401") {
              vue.label = vue.f401;
              vue.color = "black";
              vue.icon = "mdi-qrcode";
            } else if (
              response.data[0].e == "-400" ||
              response.data[0].e == "-402" ||
              response.data[0].e == "-404"
            ) {
              vue.label = vue.f400 + response.data[0].e + ")";
              vue.color = "error";
              vue.icon = "mdi-close-circle-outline";
              console.log(response.data[0].id);
            } else if (response.data[0].e == "-1") {
              vue.label = vue.f1;
              vue.color = "blue darken-4";
              vue.icon = "mdi-qrcode-scan";
            } else if (response.data[0].e == "0") {
              vue.label = vue.e0;
              vue.color = "warning";
              vue.icon = "mdi-sync";
            } else if (response.data[0].e == "1") {
              vue.label = vue.e1;
              vue.color = "success";
              vue.icon = "mdi-check";
              vue.countDown = 0;
              vue.hecho = response.data[0];
              vue.dialogsi = true;
            } else if (response.data[0].e == "2") {
              vue.label = vue.e2;
              vue.color = "error";
              vue.icon = "mdi-close";
            } else if (response.data[0].e == "3") {
              vue.label = vue.e3;
              vue.color = "error";
              vue.icon = "mdi-close";
            } else if (response.data[0].e == "4") {
              vue.label = vue.e4;
              vue.color = "blue darken-4";
              vue.icon = "mdi-timer-outline";
            } else if (response.data[0].e == "6") {
              vue.label = vue.e6;
              vue.color = "blue darken-4";
              vue.icon = "mdi-sync";
            } else if (response.data[0].e == "21") {
              vue.label = vue.e21;
              vue.color = "error";
              vue.icon = "mdi-close";
            } else if (response.data[0].e == "22") {
              vue.label = vue.e22;
              vue.color = "error";
              vue.icon = "mdi-close";
            } else if (response.data[0].e == "23") {
              vue.label = vue.e23;
              vue.color = "error";
              vue.icon = "mdi-close";
            } else if (response.data[0].e == "24") {
              vue.label = vue.e24;
              vue.color = "error";
              vue.icon = "mdi-close";
            } else if (response.data[0].e == "31") {
              vue.label = vue.e31;
              vue.color = "blue darken-4";
              vue.icon = "mdi-autorenew";
            } else if (response.data[0].e == "32") {
              vue.label = vue.e32;
              vue.color = "blue darken-4";
              vue.icon = "mdi-autorenew";
            } else if (response.data[0].e == "33") {
              vue.label = vue.e33;
              vue.color = "blue darken-4";
              vue.icon = "mdi-autorenew";
            } else if (response.data[0].e == "34") {
              vue.label = vue.e34;
              vue.color = "warning";
              vue.icon = "mdi-autorenew";
            } else if (response.data[0].e == "61") {
              vue.label = vue.e61;
              vue.color = "warning";
              vue.icon = "mdi-autorenew";
            } else if (response.data[0].e == "62") {
              vue.label = vue.e62;
              vue.color = "error";
              vue.icon = "mdi-autorenew-off";
            } else if (response.data[0].e == "63") {
              vue.label = vue.e63;
              vue.color = "error";
              vue.icon = "mdi-autorenew-off";
            } else {
              vue.label = vue.f0 + response.data[0].e + ")";
              vue.color = "error";
              vue.icon = "mdi-close-circle-outline";
              console.log(response.data[0]);
            }
          } else {
            vue.label = "";
            vue.color = "indigo darken-4";
            vue.icon = "mdi-alert-circle-outline";
            for (var i = response.data.length - 1; i >= 0; i--) {
              if (response.data[i].e == "-401") {
                vue.label =
                  (response.data.length - i).toString() + ". " + vue.f401;
              } else if (
                response.data[i].e == "-400" ||
                response.data[i].e == "-402" ||
                response.data[i].e == "-404"
              ) {
                vue.label =
                  (response.data.length - i).toString() +
                  ". " +
                  vue.f400 +
                  response.data[i].e +
                  ")";
                console.log(response.data[i].id);
              } else if (response.data[i].e == "-1") {
                vue.label =
                  vue.label +
                  (response.data.length - i).toString() +
                  ". " +
                  vue.f1;
              } else if (response.data[i].e == "0") {
                vue.label =
                  vue.label +
                  (response.data.length - i).toString() +
                  ". " +
                  vue.e0;
              } else if (response.data[i].e == "1") {
                vue.label =
                  vue.label +
                  (response.data.length - i).toString() +
                  ". " +
                  vue.e1;
              } else if (response.data[i].e == "2") {
                vue.label =
                  vue.label +
                  (response.data.length - i).toString() +
                  ". " +
                  vue.e2;
              } else if (response.data[i].e == "3") {
                vue.label =
                  vue.label +
                  (response.data.length - i).toString() +
                  ". " +
                  vue.e3;
              } else if (response.data[i].e == "4") {
                vue.label =
                  vue.label +
                  (response.data.length - i).toString() +
                  ". " +
                  vue.e4;
              } else if (response.data[i].e == "6") {
                vue.label =
                  vue.label +
                  (response.data.length - i).toString() +
                  ". " +
                  vue.e6;
              } else if (response.data[i].e == "21") {
                vue.label =
                  vue.label +
                  (response.data.length - i).toString() +
                  ". " +
                  vue.e21;
              } else if (response.data[i].e == "22") {
                vue.label =
                  vue.label +
                  (response.data.length - i).toString() +
                  ". " +
                  vue.e22;
              } else if (response.data[i].e == "23") {
                vue.label =
                  vue.label +
                  (response.data.length - i).toString() +
                  ". " +
                  vue.e23;
              } else if (response.data[i].e == "24") {
                vue.label =
                  vue.label +
                  (response.data.length - i).toString() +
                  ". " +
                  vue.e24;
              } else if (response.data[i].e == "31") {
                vue.label =
                  vue.label +
                  (response.data.length - i).toString() +
                  ". " +
                  vue.e31;
              } else if (response.data[i].e == "32") {
                vue.label =
                  vue.label +
                  (response.data.length - i).toString() +
                  ". " +
                  vue.e32;
              } else if (response.data[i].e == "33") {
                vue.label =
                  vue.label +
                  (response.data.length - i).toString() +
                  ". " +
                  vue.e33;
              } else if (response.data[i].e == "34") {
                vue.label =
                  vue.label +
                  (response.data.length - i).toString() +
                  ". " +
                  vue.e34;
              } else if (response.data[i].e == "61") {
                vue.label =
                  vue.label +
                  (response.data.length - i).toString() +
                  ". " +
                  vue.e61;
              } else if (response.data[i].e == "62") {
                vue.label =
                  vue.label +
                  (response.data.length - i).toString() +
                  ". " +
                  vue.e62;
              } else if (response.data[i].e == "63") {
                vue.label =
                  vue.label +
                  (response.data.length - i).toString() +
                  ". " +
                  vue.e63;
              } else {
                vue.label =
                  vue.label +
                  (response.data.length - i).toString() +
                  ". " +
                  vue.f0 +
                  response.data[i].e +
                  ")";

                console.log(response.data[i]);
              }
              vue.label = vue.label + "\n";
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    countDownTimer() {
      if ((this.dialogcodi || this.dialogpush) && this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.update();
          this.countDownTimer();
        }, 2500);
      }
    },
    imprimir() {
      let vue = this;
      vue.dialog = true;
      var Param = {
        fecha: vue.hecho.hp,
        monto: vue.hecho.mt,
        con: vue.hecho.cc,
        refe: vue.referencia,
        clave: vue.hecho.cr,
        nom: vue.$store.state.nombre,
      };
      axios
        .post("api/Codi/ImprimirComprobante", Param)
        .then(function (response) {
          vue.dialog = false;
          vue.print(response.data.imagen);
        })
        .catch(function (error) {
          vue.dialog = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },
    imagen() {
      let vue = this;
      vue.dialog = true;
      var Param = {
        fecha: vue.hecho.hp,
        monto: vue.hecho.mt,
        con: vue.hecho.cc,
        refe: vue.referencia,
        clave: vue.hecho.cr,
        nom: vue.$store.state.nombre,
      };
      axios
        .post("api/Codi/DescargarComprobante", Param)
        .then(function (response) {
          vue.dialog = false;
          vue.downloadcanvas(response.data.file, response.data.name);
        })
        .catch(function (error) {
          vue.dialog = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },
    funcorreo() {
      if (this.$refs.formA.validate()) {
        let vue = this;
        vue.dialogA = false;
        vue.dialog = true;
        var Param = {
          fecha: vue.hecho.hp,
          monto: vue.hecho.mt,
          con: vue.hecho.cc,
          refe: vue.referencia,
          clave: vue.hecho.cr,
          nom: vue.$store.state.nombre,
          correo: vue.correo,
        };
        axios
          .post("api/Codi/EnviarComprobante", Param)
          .then(function (response) {
            vue.dialog = false;
            vue.dialogB = true;
            vue.correo = "";
          })
          .catch(function (error) {
            vue.dialog = false;
            vue.correo = "";
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      }
    },

    impcodi() {
      let vue = this;
      vue.dialog = true;
      var Param = {
        imagen: vue.imagefinal,
      };
      axios
        .post("api/Codi/ImprimirCodi", Param)
        .then(function (response) {
          vue.dialog = false;
          vue.print(response.data.imagen);
        })
        .catch(function (error) {
          vue.dialog = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },
    imagencodi() {
      let vue = this;
      vue.dialog = true;
      var Param = {
        imagen: vue.imagefinal,
      };
      axios
        .post("api/Codi/DescargarCodi", Param)
        .then(function (response) {
          vue.dialog = false;
          vue.downloadcanvas(response.data.file, response.data.name);
        })
        .catch(function (error) {
          vue.dialog = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },
    correocodi() {
      if (this.$refs.formAA.validate()) {
        let vue = this;
        vue.dialogAA = false;
        vue.dialog = true;
        var Param = {
          imagen: vue.imagefinal,
          correo: vue.correoo,
        };
        axios
          .post("api/Codi/EnviarCodi", Param)
          .then(function (response) {
            vue.dialog = false;
            vue.dialogBB = true;
            vue.correoo = "";
          })
          .catch(function (error) {
            vue.dialog = false;
            vue.correoo = "";
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      }
    },

    print(imagen) {
      var ventana = window.open("", "PRINT");
      ventana.document.write(imagen);
      ventana.document.close();
      ventana.focus();
      setTimeout(() => {
        ventana.print();
      }, 500);
    },

    cerrarA() {
      this.dialogA = false;
      this.correo = "";
    },
    cerrarAA() {
      this.dialogAA = false;
      this.correoo = "";
    },
    cerrarC() {
      this.dialogC = false;
      this.telefonoo = "";
      this.$refs.formCC.resetValidation();
    },
    cerrargen() {
      this.dialogGeneral = false;
      this.tam = "300";
      this.$refs.formgen.resetValidation();
      this.font = "25";
      this.$refs.formfont.resetValidation();
    },
    downloadcanvas(file, name) {
      document.getElementById("insertar").innerHTML = "";
      var doc = document.getElementById("insertar");
      doc.insertAdjacentHTML("beforeend", file);

      html2canvas(document.getElementById("imagen"))
        .then((canvas) => {
          const linkSource = canvas.toDataURL("image/png");
          const downloadLink = document.createElement("a");
          const fileName = name;

          document.getElementById("insertar").innerHTML = "";

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    telef() {
      var a = this.$refs.form1.validate();
      var b = this.$refs.form2.validate();
      var c = this.$refs.form3.validate();
      if (a && b && c) {
        this.dialogC = true;
      }
    },
    blurAll() {
      var tmp = document.createElement("input");
      document.body.appendChild(tmp);
      tmp.focus();
      document.body.removeChild(tmp);
    },
  },
  mounted() {
    this.$store.dispatch("autologinA");
  },
};
</script>

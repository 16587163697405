<template>
  <v-row
    ><v-col>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        disable-sort
        sort-by="id_usuario"
        class="elevation-1"
        :footer-props="{ itemsPerPageText: 'Filas por página:' }"
        :items-per-page="-1"
      >
        <template v-slot:top>
          <v-card-title>
            <v-toolbar flat>
              <v-icon>mdi-cogs</v-icon>
              <v-divider class="mx-4" vertical></v-divider>
              <v-toolbar-title>Solicitudes Usuarios</v-toolbar-title>
            </v-toolbar>
          </v-card-title>
        </template>
        <template v-slot:[`item.cod_cuenta`]="{ item }">
          {{ funbanco(item.cod_cuenta) }}
          <br />
          {{ item.cod_cuenta }}
        </template>
        <template v-slot:[`item.act`]="{ item }">
          <v-icon title="Activar" @click="active(item)"
            >mdi-lock-open-check</v-icon
          >
        </template>
        <template v-slot:[`item.rutaCif`]="{ item }">
          <template v-if="item.rutaCif != ''">
            <v-icon
              title="Descargar"
              @click="download(item.rutaCif, item.usuario)"
              >mdi-download</v-icon
            >
          </template>
        </template>
        <template v-slot:[`item.rutaCer`]="{ item }">
          <template v-if="item.rutaCer != ''">
            <v-icon
              title="Descargar"
              @click="download(item.rutaCer, item.usuario)"
              >mdi-download</v-icon
            >
          </template>
        </template>
        <template v-slot:[`item.rutaKey`]="{ item }">
          <template v-if="item.rutaKey != ''">
            <v-icon
              title="Descargar"
              @click="download(item.rutaKey, item.usuario)"
              >mdi-download</v-icon
            >
          </template>
        </template>
        <template v-slot:no-data>
          <br />
          <p>Sin datos disponibles</p>
        </template>
      </v-data-table>
      <br />
      <v-data-table
        dense
        :headers="headers2"
        :items="items2"
        disable-sort
        sort-by="id_cuenta"
        class="elevation-1"
        :footer-props="{ itemsPerPageText: 'Filas por página:' }"
        :items-per-page="-1"
      >
        <template v-slot:top>
          <v-card-title>
            <v-toolbar flat>
              <v-icon>mdi-cogs</v-icon>
              <v-divider class="mx-4" vertical></v-divider>
              <v-toolbar-title
                >Solicitudes Cuentas beneficiarias</v-toolbar-title
              >
            </v-toolbar>
          </v-card-title>
        </template>
        <template v-slot:[`item.act`]="{ item }">
          <v-icon title="Activar" @click="activecuenta(item)"
            >mdi-lock-open-check</v-icon
          >
        </template>
        <template v-slot:no-data>
          <br />
          <p>Sin datos disponibles</p>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogIni" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Cargando
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogactive" max-width="800">
        <v-card>
          <v-card-title>
            <span class="text-h5"
              >Activar a: {{ usu_final }} ({{ id_final }})</span
            >
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col dense cols="6">
                  <v-form ref="form1" onSubmit="return false;">
                    <v-text-field
                      v-model="objeto.base"
                      label="Base de datos"
                      :rules="specialrule"
                      outlined
                      small
                      dense
                      :color="colo"
                      @keydown.enter="handleFormSubmit"
                    >
                      <template v-slot:append>
                        <v-icon
                          :color="color"
                          title="Verificar usuario"
                          @click="handleFormSubmit"
                        >
                          {{ icon }}
                        </v-icon>
                      </template>
                    </v-text-field></v-form
                  >
                </v-col>
                <v-col dense cols="6"
                  ><v-form ref="form2" onSubmit="return false;">
                    <v-text-field
                      v-model="objeto.certificado"
                      :rules="[rules.required, rules.max]"
                      label="Certificado"
                      outlined
                      small
                      dense
                    ></v-text-field
                  ></v-form>
                </v-col>
                <v-col dense cols="6"
                  ><v-form ref="form3" onSubmit="return false;">
                    <v-text-field
                      v-model="objeto.alias"
                      :rules="[rules.required, rules.max]"
                      label="Alias"
                      outlined
                      small
                      dense
                    >
                    </v-text-field
                  ></v-form>
                </v-col>
                <v-col dense cols="6"
                  ><v-form ref="form4" onSubmit="return false;">
                    <v-text-field
                      v-model="objeto.digito"
                      :rules="[rules.required, rules.dig]"
                      label="Digito Verificado"
                      outlined
                      small
                      dense
                    ></v-text-field
                  ></v-form>
                </v-col>
                <v-col dense cols="12"
                  ><v-form ref="form5" onSubmit="return false;">
                    <v-tooltip bottom v-model="show">
                      <template v-slot:activator="{}">
                        <v-textarea
                          v-model="objeto.cadena"
                          :rules="[rules.required]"
                          label="Elemento Cifrado"
                          auto-grow
                          outlined
                          small
                          dense
                        ></v-textarea>
                      </template>
                      {{ mess }}
                    </v-tooltip>
                  </v-form>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" @click="cancelfun" text> Cancelar </v-btn>
            <v-btn color="program" @click="savefun" text> Guardar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialognotyet" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Faltan datos para activar</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialognotyet = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogcuenta" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>¿Desea activar la cuenta de banco?</h5>
          </v-card-text>
          <v-card-actions>
            <v-btn color="program" text @click="cancelcuenta"> Cancelar </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="savecuenta"> Aceptar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog500" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Problemas de conexión</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog500 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog404" max-width="350">
        <v-card>
          <v-card-title class="headline"> Error </v-card-title>
          <v-card-text class="text-h6">
            <h5>{{ Message }}</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog404 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
 
<script>
import axios from "axios";
export default {
  data: () => ({
    Message: "Internal Error",
    cuenta_nom: "",
    cuenta_bd: "",
    cuenta_id: "",
    id_final: "",
    usu_final: "",
    nom_final: "",
    cod_final: "",
    cuenta_final: "",
    pass_final: "",
    dialogIni: false,
    dialog500: false,
    dialog404: false,
    dialogactive: false,
    dialognotyet: false,
    dialogcuenta: false,
    icon: "mdi-earth-arrow-right",
    color: "blue darken-4",
    colo: "program",
    mess: "",
    show: false,
    bancos: [],
    claves: [],
    headers: [
      {
        text: "ID",
        align: "start",
        value: "id_usuario",
      },
      { text: "User", value: "usuario", align: "center" },
      { text: "Nombre", value: "razon_Social", align: "center" },
      { text: "Correo", value: "correo", align: "center" },
      { text: "Teléfono", value: "telefono", align: "center" },
      { text: "Tipo", value: "persona", align: "center" },
      { text: "RFC", value: "rfc", align: "center" },
      { text: "Ruta CSF", value: "rutaCif", align: "center" },
      { text: "Ruta CER", value: "rutaCer", align: "center" },
      { text: "Ruta KEY", value: "rutaKey", align: "center" },
      { text: "Clave KEY", value: "passKey", align: "center" },
      { text: "Cuenta", value: "cuenta", align: "center" },
      { text: "Codigo banco", value: "cod_cuenta", align: "center" },
      { text: "Activar", value: "act", sorteable: false, align: "center" },
    ],
    headers2: [
      {
        text: "ID",
        align: "start",
        value: "id_cuenta",
      },
      { text: "Banco", value: "nombre_banco", align: "center" },
      { text: "Clave", value: "clave_banco", align: "center" },
      { text: "Numero de cuenta", value: "numero_cuenta", align: "center" },
      { text: "Tipo", value: "tipo", align: "center" },
      { text: "Base de datos", value: "bd", align: "center" },
      { text: "Activar", value: "act", sorteable: false, align: "center" },
    ],
    items: [],
    items2: [],
    objeto: {
      base: "",
      certificado: "",
      alias: "",
      digito: "",
      cadena: "",
    },
    rules: {
      required: (value) => !!value || "Requerido",
      max: (value) => {
        const pattern = /^[0-9]{20}$/;
        return pattern.test(value) || "Certificado inválido";
      },
      dig: (value) => {
        const pattern = /^[0-9]*$/;
        return pattern.test(value) || "Digito inválido";
      },
    },
  }),
  watch: {
    bas() {
      this.icon = "mdi-earth-arrow-right";
      this.color = "blue darken-4";
      this.colo = "program";
      this.mess = "";
      this.show = false;
    },
  },
  computed: {
    bas() {
      return this.objeto.base;
    },
    specialrule() {
      const rules = [];
      const ruler = (v) => !!v || "Requerido";
      rules.push(ruler);
      const pattern = /^[c]{1}[o]{1}[d]{1}[i]{1}[_]{1}[A-Za-z0-9]{1,35}$/;
      const rule0 = (value) => pattern.test(value) || "Base inválida";
      rules.push(rule0);
      const rule1 = (value) =>
        this.icon != "mdi-earth-arrow-right" || "Falta validar usuario";
      rules.push(rule1);
      const rule = (value) => this.icon != "mdi-close" || "Usuario inválido";
      rules.push(rule);
      return rules;
    },
  },
  methods: {
    initialize() {
      this.items = [];
      this.cargarselect();
      this.cargartodo();
    },

    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },

    cargarselect() {
      var vue = this;
      vue.bancos = [];
      vue.claves = [];
      axios
        .post("api/Codi/Instituciones")
        .then(function (response) {
          for (var i = 0; i < response.data.length; i++) {
            vue.bancos.push(response.data[i].nombreCorto);
            vue.claves.push(response.data[i].clave_institucion);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    cargartodo() {
      var vue = this;
      vue.dialogIni = true;
      if (
        vue.$store.state.empresa == null ||
        vue.$store.state.empresa == "" ||
        vue.$store.state.usuario == null ||
        vue.$store.state.usuario == "" ||
        vue.$store.state.pass == null ||
        vue.$store.state.pass == ""
      ) {
        vue.reloadPage();
        return;
      }
      var Params = {
        usuario: vue.$store.state.usuario,
        pass: vue.$store.state.pass,
        empresa: vue.$store.state.empresa,
      };
      axios
        .post("api/Codi/CargarSol", Params)
        .then(function (response) {
          vue.items = response.data.usuarios;
          vue.items2 = response.data.cuentas;
          vue.dialogIni = false;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },

    funbanco(codigo) {
      return this.bancos[this.claves.indexOf(codigo)];
    },
    sub1(word) {
      return word.Substr(0, 10);
    },
    sub2(word) {
      return word.Substr(11);
    },
    download(path, user) {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        path: path,
        usuario: user,
      };
      axios
        .post("api/Codi/CargarSolFile", Params)
        .then(function (response) {
          vue.dialogIni = false;

          const linkSource = response.data.file;
          const downloadLink = document.createElement("a");
          const fileName = response.data.name;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },
    active(item) {
      if (
        item.persona != "" &&
        item.rfc != "" &&
        item.rutaCif != "" &&
        item.rutaCer != "" &&
        item.rutaKey != "" &&
        item.passKey != "" &&
        item.cuenta != "" &&
        item.cod_cuenta != ""
      ) {
        this.id_final = item.id_usuario;
        this.usu_final = item.usuario;
        this.cod_final = item.cod_cuenta;
        this.nom_final = this.funbanco(item.cod_cuenta);
        this.cuenta_final = item.cuenta;
        this.pass_final = item.passKey;
        this.dialogactive = true;
      } else {
        this.dialognotyet = true;
      }
    },

    savefun() {
      var a = this.$refs.form1.validate();
      var b = this.$refs.form2.validate();
      var c = this.$refs.form3.validate();
      var d = this.$refs.form4.validate();
      var e = this.$refs.form5.validate();
      if (a && b && c && d && e) {
        var vue = this;
        vue.dialogIni = true;
        var Params = {
          id_usuario: vue.id_final,
          usuario: vue.usu_final,
          nom: vue.nom_final,
          cod: vue.cod_final,
          cuenta: vue.cuenta_final,
          database: vue.objeto.base,
          certificado: vue.objeto.certificado,
          alias: vue.objeto.alias,
          digito: vue.objeto.digito,
          cadena: vue.objeto.cadena,
          passkey: vue.pass_final,
        };
        axios
          .post("api/Codi/Activate", Params)
          .then(function (response) {
            vue.items = response.data.usuarios;
            vue.items2 = response.data.cuentas;
            vue.dialogIni = false;
            vue.dialogactive = false;
          })
          .catch(function (error) {
            vue.dialogIni = false;
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      }
    },
    cancelfun() {
      this.$refs.form1.reset();
      this.$refs.form2.reset();
      this.$refs.form3.reset();
      this.$refs.form4.reset();
      this.$refs.form5.reset();
      this.icon = "mdi-earth-arrow-right";
      this.color = "blue darken-4";
      this.colo = "program";
      this.mess = "";
      this.show = false;
      this.id_final = "";
      this.usu_final = "";
      this.nom_final = "";
      this.cod_final = "";
      this.cuenta_final = "";
      this.pass_final = "";
      this.dialogactive = false;
    },

    activecuenta(item) {
      this.cuenta_nom = item.numero_cuenta;
      this.cuenta_bd = item.bd;
      this.cuenta_id = item.id_cuenta;
      this.dialogcuenta = true;
    },
    savecuenta() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        id_cuenta: vue.cuenta_id,
        bd: vue.cuenta_bd,
        numero_cuenta: vue.cuenta_nom,
      };
      axios
        .post("api/Codi/ActivateCuenta", Params)
        .then(function (response) {
          vue.items = response.data.usuarios;
          vue.items2 = response.data.cuentas;
          vue.dialogIni = false;
          vue.dialogcuenta = false;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },
    cancelcuenta() {
      this.cuenta_nom = "";
      this.cuenta_bd = "";
      this.cuenta_id = "";
      this.dialogcuenta = false;
    },

    handleFormSubmit() {
      var vue = this;
      if (vue.objeto.base == "") {
        vue.$refs.form1.validate();
        return;
      }
      if (vue.objeto.base.length < 6) {
        vue.$refs.form1.validate();
        return;
      }
      if (vue.icon == "mdi-check" || vue.icon == "mdi-close") {
        return;
      }
      var Params = {
        datos: vue.objeto.base,
      };
      axios
        .post("api/Codi/ChecarBase", Params)
        .then(function (response) {
          if (response.data.datos == "no") {
            vue.boolno();
          } else {
            vue.boolyes();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    boolyes() {
      this.show = true;
      this.icon = "mdi-check";
      this.color = "success";
      this.colo = "success";
      this.mess = "Usuario Válido";
      this.$refs.form1.validate();
      setTimeout(() => {
        this.show = false;
        this.colo = "program";
      }, 2000);
    },
    boolno() {
      this.show = true;
      this.icon = "mdi-close";
      this.color = "error";
      this.colo = "error";
      this.mess = "Usuario Inválido";
      this.$refs.form1.validate();
      setTimeout(() => {
        this.show = false;
        this.colo = "program";
      }, 2000);
    },
  },
  mounted() {
    this.$store.dispatch("autologinD");
    this.initialize();
  },
};
</script>
<template>
  <v-row dense
    ><v-col dense>
      <v-card height="575">
        <v-card-title>
          <v-toolbar flat>
            <v-icon>mdi-information-outline</v-icon>
            <v-divider class="mx-4" vertical></v-divider>
            <v-toolbar-title>Datos</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-carousel
            :dark="$vuetify.theme.dark"
            :light="!$vuetify.theme.dark"
            v-model="model"
            height="435"
            hide-delimiter-background
            hide-delimiters
            :continuous="false"
          >
            <v-carousel-item>
              <v-sheet color="white" tile>
                <v-row dense>
                  <v-col cols="2"> </v-col>
                  <v-col dense cols="8">
                    <v-row>
                      <v-col dense cols="12">
                        <v-toolbar-title>Datos de Contacto</v-toolbar-title>
                        <br />
                      </v-col>
                      <v-col dense cols="12"
                        ><v-form ref="form1" onSubmit="return false;">
                          <v-text-field
                            v-model="datos.razon_Social"
                            label="Nombre o Razón Social"
                            outlined
                            small
                            :rules="[rules.required]"
                            :readonly="datos.estado == 'Activo'"
                            dense
                          ></v-text-field></v-form
                      ></v-col>
                      <v-col dense cols="12"
                        ><v-form ref="form2" onSubmit="return false;">
                          <v-text-field
                            v-model="datos.correo"
                            label="Correo"
                            outlined
                            small
                            :rules="[rules.required, rules.email]"
                            :readonly="datos.estado == 'Activo'"
                            dense
                          ></v-text-field></v-form
                      ></v-col>
                      <v-col dense cols="12"
                        ><v-form ref="form3" onSubmit="return false;">
                          <v-text-field
                            v-model="datos.telefono"
                            label="Teléfono"
                            outlined
                            small
                            :rules="[rules.required, rules.tel]"
                            :readonly="datos.estado == 'Activo'"
                            dense
                          ></v-text-field></v-form
                      ></v-col>
                      <v-col dense cols="12">
                        <v-row>
                          <template v-if="datos.estado != 'Activo'">
                            <v-btn
                              large
                              dark
                              block
                              color="program"
                              text
                              @click="guardar1"
                            >
                              Guardar
                            </v-btn>
                          </template>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="2"> </v-col>
                </v-row>
                <br />
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item>
              <v-sheet color="white" tile>
                <v-row>
                  <v-col cols="2"> </v-col>
                  <v-col cols="8">
                    <v-row>
                      <v-col dense cols="12">
                        <v-toolbar-title>Datos Fiscales</v-toolbar-title>
                        <br />
                      </v-col>
                      <template v-if="datos.estado != 'Activo'">
                        <v-col dense cols="12"
                          ><v-form ref="form4" onSubmit="return false;">
                            <v-select
                              v-model="datos.persona"
                              label="Tipo de persona"
                              :items="personas"
                              outlined
                              small
                              :rules="[rules.required]"
                              :readonly="datos.estado == 'Activo'"
                              dense
                            ></v-select></v-form
                        ></v-col>
                      </template>
                      <template v-else>
                        <v-col dense cols="12">
                          <v-text-field
                            v-model="datos.persona"
                            label="Tipo de persona"
                            outlined
                            small
                            readonly
                            dense
                          ></v-text-field>
                        </v-col>
                      </template>
                      <v-col dense cols="12"
                        ><v-form ref="form5" onSubmit="return false;">
                          <v-text-field
                            v-model="datos.rfc"
                            label="RFC"
                            outlined
                            small
                            :rules="[rules.required, rules.rfc]"
                            :readonly="datos.estado == 'Activo'"
                            dense
                          ></v-text-field></v-form
                      ></v-col>
                      <template v-if="datos.estado != 'Activo'">
                        <v-col dense cols="12"
                          ><v-form ref="form6" onSubmit="return false;">
                            <v-file-input
                              id="filecif"
                              accept="application/pdf"
                              @change="uploadcif()"
                              label="Constancia de situación fiscal"
                              outlined
                              small
                              :rules="specialrulecif"
                              dense
                              show-size
                              small-chips
                              prepend-inner-icon=""
                              append-icon=""
                              :clearable="false"
                              :disabled="datos.estado == 'Activo'"
                              v-model="modelcif"
                            >
                              <template v-slot:selection="{}">
                                <v-chip
                                  :style="'color: white'"
                                  :color="colorcif"
                                  small
                                >
                                  {{ modelcif.name }}
                                  <pre>&nbsp;</pre>
                                  <v-icon small>{{ iconcif }}</v-icon>
                                </v-chip>
                              </template>
                            </v-file-input>
                          </v-form></v-col
                        >
                      </template>
                      <template v-else>
                        <v-col dense cols="12">
                          <v-combobox
                            small-chips
                            outlined
                            small
                            label="Constancia de situación fiscal"
                            dense
                            append-icon=""
                            readonly
                            value="A"
                            prepend-icon="mdi-paperclip"
                          >
                            <template v-slot:selection="{}">
                              <v-chip color="success" small>
                                Archivo aceptado
                                <pre>&nbsp;</pre>
                                <v-icon small>mdi-check</v-icon>
                              </v-chip>
                            </template>
                          </v-combobox>
                        </v-col>
                      </template>
                      <v-col dense cols="12">
                        <v-row>
                          <template v-if="datos.estado != 'Activo'">
                            <v-btn
                              large
                              dark
                              block
                              color="program"
                              text
                              @click="guardar2"
                              :disabled="datos.estado == 'Activo'"
                              :class="classicon1"
                            >
                              Guardar
                            </v-btn>
                          </template>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="2"> </v-col>
                </v-row>
                <br />
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item>
              <v-sheet color="white" tile>
                <v-row>
                  <v-col cols="2"> </v-col>
                  <v-col cols="8">
                    <v-row>
                      <v-col dense cols="6">
                        <v-toolbar-title>Datos FIEL</v-toolbar-title>
                        <br />
                      </v-col>
                      <v-col cols="6">
                        <v-row>
                          <v-spacer />
                          <template v-if="model == 2">
                            <template v-if="datos.estado != 'Activo'">
                              <v-tooltip top v-model="por">
                                <template v-slot:activator="{ on }">
                                  <v-img
                                    v-on="on"
                                    id="icon"
                                    :class="classicon"
                                    @click="funporque"
                                    src="@/assets/alert.png"
                                    transition="scale-transition"
                                    max-height="50"
                                    max-width="50"
                                  ></v-img>
                                </template>
                                <h2>¿Por qué requerimos de estos datos?</h2>
                              </v-tooltip>
                            </template>
                          </template>
                          <v-spacer />
                        </v-row>
                      </v-col>
                      <template v-if="datos.estado != 'Activo'">
                        <v-col dense cols="12"
                          ><v-form ref="form7" onSubmit="return false;">
                            <v-file-input
                              id="filecer"
                              accept=".cer"
                              @change="uploadcer()"
                              label="Certificado (.cer)"
                              outlined
                              small
                              :rules="specialrulecer"
                              dense
                              show-size
                              small-chips
                              prepend-inner-icon=""
                              append-icon=""
                              :clearable="false"
                              :disabled="datos.estado == 'Activo'"
                              v-model="modelcer"
                            >
                              <template v-slot:selection="{}">
                                <v-chip
                                  :style="'color: white'"
                                  :color="colorcer"
                                  small
                                >
                                  {{ modelcer.name }}
                                  <pre>&nbsp;</pre>
                                  <v-icon small>{{ iconcer }}</v-icon>
                                </v-chip>
                              </template>
                            </v-file-input>
                          </v-form></v-col
                        >
                      </template>
                      <template v-else>
                        <v-col dense cols="12">
                          <v-combobox
                            small-chips
                            outlined
                            small
                            label="Certificado (.cer)"
                            dense
                            append-icon=""
                            readonly
                            value="A"
                            prepend-icon="mdi-paperclip"
                          >
                            <template v-slot:selection="{}">
                              <v-chip color="success" small>
                                Archivo aceptado
                                <pre>&nbsp;</pre>
                                <v-icon small>mdi-check</v-icon>
                              </v-chip>
                            </template>
                          </v-combobox>
                        </v-col>
                      </template>
                      <template v-if="datos.estado != 'Activo'">
                        <v-col dense cols="12"
                          ><v-form ref="form8" onSubmit="return false;">
                            <v-file-input
                              id="filekey"
                              accept=".key"
                              @change="uploadkey()"
                              label="Clave privada (.key)"
                              outlined
                              small
                              :rules="specialrulekey"
                              dense
                              show-size
                              small-chips
                              prepend-inner-icon=""
                              append-icon=""
                              :clearable="false"
                              :disabled="datos.estado == 'Activo'"
                              v-model="modelkey"
                            >
                              <template v-slot:selection="{}">
                                <v-chip
                                  :style="'color: white'"
                                  :color="colorkey"
                                  small
                                >
                                  {{ modelkey.name }}
                                  <pre>&nbsp;</pre>
                                  <v-icon small>{{ iconkey }}</v-icon>
                                </v-chip>
                              </template>
                            </v-file-input>
                          </v-form></v-col
                        >
                      </template>
                      <template v-else>
                        <v-col dense cols="12">
                          <v-combobox
                            small-chips
                            outlined
                            small
                            label="Clave privada (.key)"
                            dense
                            append-icon=""
                            readonly
                            value="A"
                            prepend-icon="mdi-paperclip"
                          >
                            <template v-slot:selection="{}">
                              <v-chip color="success" small>
                                Archivo aceptado
                                <pre>&nbsp;</pre>
                                <v-icon small>mdi-check</v-icon>
                              </v-chip>
                            </template>
                          </v-combobox>
                        </v-col>
                      </template>
                      <template v-if="datos.estado != 'Activo'">
                        <v-col dense cols="12"
                          ><v-form ref="form9" onSubmit="return false;">
                            <v-text-field
                              label="Contraseña de clave privada"
                              outlined
                              small
                              dense
                              :rules="[rules.required]"
                              v-model="datos.passKey"
                              type="password"
                              :readonly="datos.estado == 'Activo'"
                            ></v-text-field></v-form
                        ></v-col>
                      </template>
                      <template v-else>
                        <v-col dense cols="12">
                          <v-text-field
                            label="Contraseña de clave privada"
                            outlined
                            small
                            dense
                            readonly
                            value="••••••••••"
                          ></v-text-field>
                        </v-col>
                      </template>
                      <v-col dense cols="12">
                        <v-row>
                          <template v-if="datos.estado != 'Activo'">
                            <v-btn
                              large
                              dark
                              block
                              color="program"
                              text
                              @click="guardar3"
                              :class="classicon2"
                              :disabled="datos.estado == 'Activo'"
                            >
                              Guardar
                            </v-btn>
                          </template>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="2"> </v-col>
                </v-row>
                <br />
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item>
              <v-sheet color="white" tile>
                <v-row>
                  <v-col cols="2"> </v-col>
                  <v-col cols="8">
                    <v-row>
                      <v-col dense cols="12">
                        <v-toolbar-title>Cuenta Beneficiaria</v-toolbar-title>
                        <br />
                      </v-col>
                      <template v-if="datos.estado != 'Activo'">
                        <v-col cols="12">
                          <v-form ref="form10" onSubmit="return false;">
                            <v-select
                              @change="bancofun"
                              outlined
                              small
                              dense
                              :rules="[rules.required]"
                              :items="bancos"
                              v-model="banco"
                              label="Banco"
                              :readonly="datos.estado == 'Activo'"
                            >
                            </v-select>
                          </v-form>
                        </v-col>
                      </template>
                      <template v-else>
                        <v-col cols="12">
                          <v-text-field
                            outlined
                            small
                            dense
                            v-model="banco"
                            label="Banco"
                            readonly
                          >
                          </v-text-field> </v-col
                      ></template>
                      <v-col cols="12">
                        <v-form ref="form11" onSubmit="return false;">
                          <v-text-field
                            outlined
                            small
                            dense
                            :rules="[rules.required, rules.clabe]"
                            v-model="datos.cuenta"
                            label="Cuenta CLABE"
                            :readonly="datos.estado == 'Activo'"
                          ></v-text-field>
                        </v-form>
                      </v-col>
                      <v-col cols="12">
                        <v-form ref="form12" onSubmit="return false;">
                          <v-text-field
                            outlined
                            small
                            dense
                            :rules="[rules.required]"
                            v-model="datos.vendedor"
                            label="Nombre o Razón Social en el comprobante"
                            :readonly="datos.estado == 'Activo'"
                          ></v-text-field>
                        </v-form>
                      </v-col>
                      <v-col dense cols="12">
                        <v-row>
                          <template v-if="datos.estado != 'Activo'">
                            <v-btn
                              large
                              dark
                              block
                              color="program"
                              text
                              @click="guardar4"
                              :disabled="datos.estado == 'Activo'"
                            >
                              Guardar
                            </v-btn>
                          </template>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="2"> </v-col>
                </v-row>
                <br />
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
          <v-row dense>
            <v-spacer />
            <v-row>
              <v-spacer />
              <v-row>
                <!--class="noHover"-->
                <v-btn
                  elevation="5"
                  :style="'color: ' + col1"
                  @click="model = 0"
                  :color="color('0')"
                  fab
                  small
                >
                  <h2>1</h2>
                </v-btn>
                <v-spacer />
                <v-btn
                  elevation="5"
                  :style="'color: ' + col2"
                  @click="model = 1"
                  :color="color('1')"
                  fab
                  small
                >
                  <h2>2</h2>
                </v-btn>
                <v-spacer />
                <v-btn
                  elevation="5"
                  :style="'color: ' + col3"
                  @click="model = 2"
                  :color="color('2')"
                  fab
                  small
                >
                  <h2>3</h2>
                </v-btn>
                <v-spacer />
                <v-btn
                  elevation="5"
                  :style="'color: ' + col4"
                  @click="model = 3"
                  :color="color('3')"
                  fab
                  small
                >
                  <h2>4</h2>
                </v-btn>
              </v-row>
              <v-spacer />
            </v-row>
            <v-spacer
          /></v-row>
        </v-card-text>
      </v-card>
      <v-dialog persistent v-model="dialogsave" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>¿Desea guardar estos datos?</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialogsave = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogIni" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Cargando
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog500" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Problemas de conexión</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog500 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog404" max-width="350">
        <v-card>
          <v-card-title class="headline"> Error </v-card-title>
          <v-card-text class="text-h6">
            <h5>{{ Message }}</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog404 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogaviso" max-width="1000">
        <v-card>
          <v-card-text>
            <pre>&nbsp;&nbsp;</pre>
            <v-img src="@/assets/fiel.png" transition="scale-transition" />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialogaviso = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<style>
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.noHover {
  pointer-events: none;
}
</style>

<script>
import axios from "axios";
import App from "../App.vue";
export default {
  components: { App },
  data: () => ({
    defaultfile: {
      file: null,
      index: 0,
      multiple: false,
      text: "",
      name: "Archivo en revisión",
    },
    i: 0,
    Message: "Internal Error",
    dialogIni: false,
    dialog500: false,
    dialog404: false,
    dialogsave: false,
    dialogaviso: false,
    flag: true,
    model: 0,
    modelanterior: 0,
    col1: "white",
    col2: "black",
    col3: "black",
    col4: "black",
    por: false,
    classicon: "",
    classicon1: "",
    classicon2: "",
    modelcif: null,
    modelcer: null,
    modelkey: null,
    filecif: "",
    filecer: "",
    filekey: "",
    iconcif: "mdi-upload",
    iconcer: "mdi-upload",
    iconkey: "mdi-upload",
    colorcif: "program",
    colorcer: "program",
    colorkey: "program",
    datos: {
      razon_Social: "",
      correo: "",
      telefono: "",
      persona: "",
      rfc: "",
      rutaCif: "",
      rutaCer: "",
      rutaKey: "",
      passKey: "",
      cuenta: "",
      cod_cuenta: "",
      bd: "",
      tipo: "",
      estado: "",
      vendedor: "",
    },
    bancos: [],
    claves: [],
    banco: "",
    clave: "",
    Pass: false,
    personas: ["Persona Física", "Persona Moral"],
    rules: {
      required: (value) => !!value || "Requerido",
      razon: (value) => (value || "").length <= 40 || "Máximo 40 caracteres",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Correo inválido";
      },
      tel: (value) => {
        const pattern = /^([0-9]{10}[0-9]*)$/;
        return pattern.test(value) || "Teléfono inválido";
      },
      rfc: (value) => {
        const pattern = /^([A-Za-z]{3,4}[0-9]{6}[A-Za-z0-9]{3})$/;
        return pattern.test(value) || "RFC inválido";
      },
      clabe: (value) => {
        const pattern = /^[0-9]{18}$/;
        return pattern.test(value) || "Debe tener 18 caracteres";
      },
    },
  }),

  computed: {
    specialrulecif() {
      const rules = [];
      const ruler = (v) => !!v || this.datos.rutaCif != "" || "Requerido";
      rules.push(ruler);
      const ruler2 = (v) =>
        this.modelcif != this.defaultfile || "Sube de nuevo el archivo";
      rules.push(ruler2);
      return rules;
    },
    specialrulecer() {
      const rules = [];
      const ruler = (v) => !!v || this.datos.rutaCer != "" || "Requerido";
      rules.push(ruler);
      const ruler2 = (v) =>
        this.modelcer != this.defaultfile || "Sube de nuevo el archivo";
      rules.push(ruler2);
      return rules;
    },
    specialrulekey() {
      const rules = [];
      const ruler = (v) => !!v || this.datos.rutaKey != "" || "Requerido";
      rules.push(ruler);
      const ruler2 = (v) =>
        this.modelkey != this.defaultfile ||
        "Sube otro archivo, si deseas cambiarlo";
      rules.push(ruler2);
      return rules;
    },
  },

  watch: {
    model(val) {
      this.i = 0;
      this.por = false;
      if (val == 0) {
        this.col1 = "white";
        this.col2 = "black";
        this.col3 = "black";
        this.col4 = "black";
      } else if (val == 1) {
        this.col1 = "black";
        this.col2 = "white";
        this.col3 = "black";
        this.col4 = "black";
      } else if (val == 2) {
        this.col1 = "black";
        this.col2 = "black";
        this.col3 = "white";
        this.col4 = "black";
      } else if (val == 3) {
        this.col1 = "black";
        this.col2 = "black";
        this.col3 = "black";
        this.col4 = "white";
      }
      if (this.model > this.modelanterior) {
        if (val == 2) {
          if (
            this.datos.persona == "" ||
            this.datos.rfc == "" ||
            this.datos.rutaCif == ""
          ) {
            this.$refs.form4.validate();
            this.$refs.form5.validate();
            this.$refs.form6.validate();
            setTimeout(() => {
              this.model = 1;
              this.loop1();
            }, 10);
          } else {
            this.cargartodo();
          }
        } else if (val == 3) {
          if (
            this.datos.rutaCer == "" ||
            this.datos.rutaKey == "" ||
            this.datos.passKey == ""
          ) {
            this.$refs.form7.validate();
            this.$refs.form8.validate();
            this.$refs.form9.validate();
            setTimeout(() => {
              this.model = 2;
              this.loop2();
            }, 10);
          } else {
            this.cargartodo();
          }
        }
      } else if (this.model == this.modelanterior) {
      } else {
        this.cargartodo();
      }
    },
  },

  methods: {
    color(p) {
      if (this.model == p) {
        return "program";
      } else {
        return "white";
      }
    },
    funporque() {
      this.flag = false;
      this.dialogaviso = true;
    },

    funloop() {
      this.classicon = "shake";
      setTimeout(() => {
        this.classicon = "";
        setTimeout(() => {
          if (this.flag && this.datos.estado != "Activo") {
            if (this.i != 0) {
              this.por = true;
            } else {
              this.i = 1;
            }
            this.funloop();
          }
        }, 1000);
      }, 1000);
    },

    loop1() {
      this.classicon1 = "shake";
      setTimeout(() => {
        this.classicon1 = "";
      }, 1000);
    },

    loop2() {
      this.classicon2 = "shake";
      setTimeout(() => {
        this.classicon2 = "";
      }, 1000);
    },

    uploadcif() {
      this.dialogIni = true;
      this.iconcif = "mdi-upload";
      this.colorcif = "program";
      setTimeout(() => {
        const file = document.getElementById("filecif").files[0];
        if (file != null) {
          const reader = new FileReader();
          var vue = this;
          reader.onloadend = () => {
            this.filecif = reader.result;
            console.log(this.filecif);
          };
          reader.readAsDataURL(file);
        } else {
          this.filecif = "";
        }
        this.dialogIni = false;
      }, 100);
    },

    uploadcer() {
      this.dialogIni = true;
      this.iconcer = "mdi-upload";
      this.colorcer = "program";
      setTimeout(() => {
        const file = document.getElementById("filecer").files[0];
        if (file != null) {
          const reader = new FileReader();
          var vue = this;
          reader.onloadend = () => {
            this.filecer = reader.result;
            console.log(this.filecer);
          };
          reader.readAsDataURL(file);
        } else {
          this.filecer = "";
        }
        this.dialogIni = false;
      }, 100);
    },

    uploadkey() {
      this.dialogIni = true;
      this.iconkey = "mdi-upload";
      this.colorkey = "program";
      setTimeout(() => {
        const file = document.getElementById("filekey").files[0];
        if (file != null) {
          const reader = new FileReader();
          var vue = this;
          reader.onloadend = () => {
            this.filekey = reader.result;
            console.log(this.filekey);
          };
          reader.readAsDataURL(file);
        } else {
          this.filekey = "";
        }
        this.dialogIni = false;
      }, 100);
    },

    guardar1() {
      var a = this.$refs.form1.validate();
      var b = this.$refs.form2.validate();
      var c = this.$refs.form3.validate();
      if (a && b && c) {
        var vue = this;
        vue.dialogIni = true;
        var Params = {
          usuario: vue.$store.state.usuario,
          pass: vue.$store.state.pass,
          dat1: vue.datos.razon_Social,
          dat2: vue.datos.correo,
          dat3: vue.datos.telefono,
        };
        axios
          .post("api/Codi/GuardarDatos1", Params)
          .then(function (response) {
            vue.datos = response.data;
            vue.valid();
            vue.model = 1;
            vue.dialogIni = false;
          })
          .catch(function (error) {
            vue.dialogIni = false;
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      }
    },

    guardar2() {
      var a = this.$refs.form4.validate();
      var b = this.$refs.form5.validate();
      var c = this.$refs.form6.validate();
      if (a && b && c) {
        var vue = this;
        vue.dialogIni = true;
        var Params = {
          usuario: vue.$store.state.usuario,
          pass: vue.$store.state.pass,
          dat1: vue.datos.persona,
          dat2: vue.datos.rfc,
          dat3: vue.filecif,
        };
        axios
          .post("api/Codi/GuardarDatos2", Params)
          .then(function (response) {
            vue.datos = response.data;
            vue.valid();
            vue.model = 2;
            vue.dialogIni = false;
          })
          .catch(function (error) {
            vue.dialogIni = false;
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      }
    },

    guardar3() {
      var a = this.$refs.form7.validate();
      var b = this.$refs.form8.validate();
      var c = this.$refs.form9.validate();
      if (a && b && c) {
        var vue = this;
        vue.dialogIni = true;
        var Params = {
          usuario: vue.$store.state.usuario,
          pass: vue.$store.state.pass,
          dat1: vue.filecer,
          dat2: vue.filekey,
          dat3: vue.datos.passKey,
        };
        axios
          .post("api/Codi/GuardarDatos3", Params)
          .then(function (response) {
            vue.datos = response.data;
            vue.valid();
            vue.model = 3;
            vue.dialogIni = false;
          })
          .catch(function (error) {
            vue.dialogIni = false;
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      }
    },

    guardar4() {
      var a = this.$refs.form10.validate();
      var b = this.$refs.form11.validate();
      var c = this.$refs.form12.validate();
      if (a && b && c) {
        var vue = this;
        vue.dialogIni = true;
        var Params = {
          usuario: vue.$store.state.usuario,
          pass: vue.$store.state.pass,
          dat1: vue.datos.cod_cuenta,
          dat2: vue.datos.cuenta,
          dat3: vue.datos.vendedor,
        };
        axios
          .post("api/Codi/GuardarDatos4", Params)
          .then(function (response) {
            vue.datos = response.data;
            vue.valid();
            vue.dialogIni = false;
          })
          .catch(function (error) {
            vue.dialogIni = false;
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      }
    },

    cargartodo() {
      var vue = this;
      vue.dialogIni = true;
      if (
        vue.$store.state.usuario == null ||
        vue.$store.state.usuario == "" ||
        vue.$store.state.pass == null ||
        vue.$store.state.pass == ""
      ) {
        vue.reloadPage();
        return;
      }
      var Params = {
        usuario: vue.$store.state.usuario,
        pass: vue.$store.state.pass,
      };
      axios
        .post("api/Codi/CargarDatos", Params)
        .then(function (response) {
          vue.datos = response.data;
          vue.valid();
          vue.dialogIni = false;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },

    cargarselect() {
      var vue = this;
      axios
        .post("api/Codi/Instituciones")
        .then(function (response) {
          for (var i = 0; i < response.data.length; i++) {
            vue.bancos.push(response.data[i].nombreCorto);
            vue.claves.push(response.data[i].clave_institucion);
          }
          vue.cargartodo();
        })
        .catch(function (error) {
          console.log(error);
          vue.cargartodo();
        });
    },

    valid() {
      if (this.datos.cod_cuenta != "") {
        var aux = this.claves.indexOf(this.datos.cod_cuenta);
        this.banco = this.bancos[aux];
      }
      if (this.datos.rutaCif != "") {
        this.modelcif = this.defaultfile;
        this.iconcif = "mdi-sync";
        this.colorcif = "warning";
        if (this.model == 1 && this.datos.estado != "Activo") {
          this.$refs.form6.resetValidation();
        }
      } else {
        this.modelcif = null;
        this.iconcif = "mdi-upload";
        this.colorcif = "program";
      }
      if (this.datos.rutaCer != "") {
        this.modelcer = this.defaultfile;
        this.iconcer = "mdi-sync";
        this.colorcer = "warning";
        if (this.model == 2 && this.datos.estado != "Activo") {
          this.$refs.form7.resetValidation();
        }
      } else {
        this.modelcer = null;
        this.iconcer = "mdi-upload";
        this.colorcer = "program";
      }
      if (this.datos.rutaKey != "") {
        this.modelkey = this.defaultfile;
        this.iconkey = "mdi-sync";
        this.colorkey = "warning";
        if (this.model == 2 && this.datos.estado != "Activo") {
          this.$refs.form8.resetValidation();
        }
      } else {
        this.modelkey = null;
        this.iconkey = "mdi-upload";
        this.colorkey = "program";
      }
      this.modelanterior = this.model;
    },
    bancofun() {
      this.datos.cod_cuenta = this.claves[this.bancos.indexOf(this.banco)];
    },
  },
  mounted() {
    this.$store.dispatch("autologinC");
    this.cargarselect();
    this.funloop();
  },
};
</script>
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import router from '../router/index'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
    state: {
        nombre: null,
        token: null,
        usuario: null,
        pass: null,
        empresa: null,
    },
    mutations: {
        setToken(state, token) {
            state.token = token
        },
        setUsuario(state, usuario) {
            state.usuario = usuario
        },
        setPass(state, pass) {
            state.pass = pass
        },
        setEmpresa(state, empresa) {
            state.empresa = empresa
        },
        setNombre(state, nombre) {
            state.nombre = nombre
        },
    },
    actions: {
        guardartoken({ commit }, token) {
            commit("setToken", token)
            localStorage.setItem("token", token)
        },
        guardarusuario({ commit }, usuario) {
            commit("setUsuario", usuario)
            localStorage.setItem("usuario", usuario)
        },
        guardarpass({ commit }, pass) {
            commit("setPass", pass)
            localStorage.setItem("pass", pass)
        },
        guardarempresa({ commit }, empresa) {
            commit("setEmpresa", empresa)
            localStorage.setItem("empresa", empresa)
        },
        guardarnombre({ commit }, nombre) {
            commit("setNombre", nombre)
            localStorage.setItem("nombre", nombre)
        },
        autologinA({ commit }) {
            let u = localStorage.getItem("usuario")
            let p = localStorage.getItem("pass")
            var Params = {
                user: u,
                pass: p,
            };
            axios
                .post("api/Codi/LoginUsuariosIni", Params)
                .then(function(response) {
                    commit("setToken", response.data.token)
                    localStorage.setItem("token", response.data.token)
                    commit("setNombre", response.data.nombre)
                    localStorage.setItem("nombre", response.data.nombre)
                    commit("setUsuario", response.data.user)
                    localStorage.setItem("usuario", response.data.user)
                    commit("setPass", response.data.pass)
                    localStorage.setItem("pass", response.data.pass)
                    commit("setEmpresa", response.data.empresa)
                    localStorage.setItem("empresa", response.data.empresa)
                    document.getElementById("todo").style.display = "block";
                    if (response.data.empresa == "") {
                        router.push({ name: "Datos" });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    commit("setToken", null)
                    commit("setNombre", null)
                    commit("setUsuario", null)
                    commit("setPass", null)
                    commit("setEmpresa", null)
                    localStorage.removeItem("token")
                    localStorage.removeItem("nombre")
                    localStorage.removeItem("usuario")
                    localStorage.removeItem("pass")
                    localStorage.removeItem("empresa")
                    document.getElementById("todo").style.display = "none";
                    router.push({ name: "Home" });
                });
        },
        autologinB({ commit }) {
            let u = localStorage.getItem("usuario")
            let p = localStorage.getItem("pass")
            var Params = {
                user: u,
                pass: p,
            };
            axios
                .post("api/Codi/LoginUsuariosIni", Params)
                .then(function(response) {

                    commit("setToken", response.data.token)
                    localStorage.setItem("token", response.data.token)
                    commit("setNombre", response.data.nombre)
                    localStorage.setItem("nombre", response.data.nombre)
                    commit("setUsuario", response.data.user)
                    localStorage.setItem("usuario", response.data.user)
                    commit("setPass", response.data.pass)
                    localStorage.setItem("pass", response.data.pass)
                    commit("setEmpresa", response.data.empresa)
                    localStorage.setItem("empresa", response.data.empresa)
                    document.getElementById("todo").style.display = "block";
                    if (response.data.empresa == "") {
                        router.push({ name: "Datos" });
                    } else {
                        if (response.data.token != "Admin" && response.data.token != "Root") {
                            router.push({ name: "Codi" });
                        }
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    commit("setToken", null)
                    commit("setNombre", null)
                    commit("setUsuario", null)
                    commit("setPass", null)
                    commit("setEmpresa", null)
                    localStorage.removeItem("token")
                    localStorage.removeItem("nombre")
                    localStorage.removeItem("usuario")
                    localStorage.removeItem("pass")
                    localStorage.removeItem("empresa")
                    document.getElementById("todo").style.display = "none";
                    router.push({ name: "Home" });
                });
        },
        autologinC({ commit }) {
            let u = localStorage.getItem("usuario")
            let p = localStorage.getItem("pass")
            var Params = {
                user: u,
                pass: p,
            };
            axios
                .post("api/Codi/LoginUsuariosIni", Params)
                .then(function(response) {
                    commit("setToken", response.data.token)
                    localStorage.setItem("token", response.data.token)
                    commit("setNombre", response.data.nombre)
                    localStorage.setItem("nombre", response.data.nombre)
                    commit("setUsuario", response.data.user)
                    localStorage.setItem("usuario", response.data.user)
                    commit("setPass", response.data.pass)
                    localStorage.setItem("pass", response.data.pass)
                    commit("setEmpresa", response.data.empresa)
                    localStorage.setItem("empresa", response.data.empresa)
                    document.getElementById("todo").style.display = "block";
                    if (response.data.token != "Admin" && response.data.token != "Root") {
                        router.push({ name: "Codi" });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    commit("setToken", null)
                    commit("setNombre", null)
                    commit("setUsuario", null)
                    commit("setPass", null)
                    commit("setEmpresa", null)
                    localStorage.removeItem("token")
                    localStorage.removeItem("nombre")
                    localStorage.removeItem("usuario")
                    localStorage.removeItem("pass")
                    localStorage.removeItem("empresa")
                    document.getElementById("todo").style.display = "none";
                    router.push({ name: "Home" });
                });
        },
        autologinD({ commit }) {
            let u = localStorage.getItem("usuario")
            let p = localStorage.getItem("pass")
            var Params = {
                user: u,
                pass: p,
            };
            axios
                .post("api/Codi/LoginUsuariosIni", Params)
                .then(function(response) {
                    commit("setToken", response.data.token)
                    localStorage.setItem("token", response.data.token)
                    commit("setNombre", response.data.nombre)
                    localStorage.setItem("nombre", response.data.nombre)
                    commit("setUsuario", response.data.user)
                    localStorage.setItem("usuario", response.data.user)
                    commit("setPass", response.data.pass)
                    localStorage.setItem("pass", response.data.pass)
                    commit("setEmpresa", response.data.empresa)
                    localStorage.setItem("empresa", response.data.empresa)
                    document.getElementById("todo").style.display = "block";
                    if (response.data.token != "Root") {
                        if (response.data.empresa == "") {
                            router.push({ name: "Datos" });
                        } else {
                            router.push({ name: "Codi" });
                        }
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    commit("setToken", null)
                    commit("setNombre", null)
                    commit("setUsuario", null)
                    commit("setPass", null)
                    commit("setEmpresa", null)
                    localStorage.removeItem("token")
                    localStorage.removeItem("nombre")
                    localStorage.removeItem("usuario")
                    localStorage.removeItem("pass")
                    localStorage.removeItem("empresa")
                    document.getElementById("todo").style.display = "none";
                    router.push({ name: "Home" });
                });
        },
        homelogin({ commit }) {
            let u = localStorage.getItem("usuario")
            let p = localStorage.getItem("pass")
            var Params = {
                user: u,
                pass: p,
            };
            axios
                .post("api/Codi/LoginUsuariosIni", Params)
                .then(function(response) {
                    commit("setToken", response.data.token)
                    localStorage.setItem("token", response.data.token)
                    commit("setNombre", response.data.nombre)
                    localStorage.setItem("nombre", response.data.nombre)
                    commit("setUsuario", response.data.user)
                    localStorage.setItem("usuario", response.data.user)
                    commit("setPass", response.data.pass)
                    localStorage.setItem("pass", response.data.pass)
                    commit("setEmpresa", response.data.empresa)
                    localStorage.setItem("empresa", response.data.empresa)
                    document.getElementById("todo").style.display = "block";
                    if (response.data.empresa == "") {
                        router.push({ name: "Datos" });
                    } else {
                        router.push({ name: "Codi" });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    commit("setToken", null)
                    commit("setNombre", null)
                    commit("setUsuario", null)
                    commit("setPass", null)
                    commit("setEmpresa", null)
                    localStorage.removeItem("token")
                    localStorage.removeItem("nombre")
                    localStorage.removeItem("usuario")
                    localStorage.removeItem("pass")
                    localStorage.removeItem("empresa")
                    document.getElementById("todo").style.display = "none";
                });
        },
        appsalir({ commit }) {
            commit("setToken", null)
            commit("setNombre", null)
            commit("setUsuario", null)
            commit("setPass", null)
            commit("setEmpresa", null)
            localStorage.removeItem("token")
            localStorage.removeItem("nombre")
            localStorage.removeItem("usuario")
            localStorage.removeItem("pass")
            localStorage.removeItem("empresa")
            document.getElementById("todo").style.display = "none";
            router.push({ name: "Home" });
        },
        nada({ commit }) {
            commit("setToken", null)
            commit("setNombre", null)
            commit("setUsuario", null)
            commit("setPass", null)
            commit("setEmpresa", null)
            localStorage.removeItem("token")
            localStorage.removeItem("nombre")
            localStorage.removeItem("usuario")
            localStorage.removeItem("pass")
            localStorage.removeItem("empresa")
            document.getElementById("todo").style.display = "none";
        },
    },
})
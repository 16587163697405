<template>
  <div>
    <v-row
      ><v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="items"
          class="elevation-1"
          :footer-props="{ itemsPerPageText: 'Filas por página:' }"
          :items-per-page="-1"
          :item-class="itemRowBackground"
        >
          <template v-slot:top>
            <v-card-title>
              <v-toolbar flat>
                <v-col cols="11">
                  <v-select
                    label="Usuario"
                    hide-details
                    locale="es"
                    :items="usuarios"
                    v-model="usuario"
                    :readonly="read"
                    class="tam"
                    @change="cargartodo"
                  ></v-select>
                </v-col>
                <v-col cols="1">
                  <v-btn icon dense large text @click="excel()">
                    <v-icon title="Exportar Tabla">mdi-microsoft-excel</v-icon>
                  </v-btn>
                </v-col>
              </v-toolbar>
            </v-card-title>
            <v-card-title>
              <v-toolbar flat>
                <v-icon>mdi-cash-register</v-icon>
                <v-divider class="mx-4" vertical></v-divider>
                <v-menu
                  v-model="menuDateBuscaA"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateFormattedMomentjsA"
                      label="Fecha de Búsqueda Inicial: "
                      readonly
                      v-bind="attrs"
                      hide-details
                      v-on="on"
                      locale="es"
                      class="tam"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="es"
                    v-model="fecha1"
                    @change="funfecha1"
                  ></v-date-picker>
                </v-menu>
                <v-divider class="mx-4" vertical></v-divider>
                <v-checkbox v-model="check"></v-checkbox>
                <v-menu
                  v-model="menuDateBuscaB"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateFormattedMomentjsB"
                      label="Fecha de Búsqueda Final: "
                      readonly
                      v-bind="attrs"
                      hide-details
                      v-on="on"
                      locale="es"
                      :disabled="!check"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="es"
                    v-model="fecha2"
                    @change="funfecha2"
                  ></v-date-picker>
                </v-menu>
                <v-divider class="mx-4" vertical></v-divider>
                <v-checkbox
                  v-model="check2"
                  class="down"
                  label="Solo Pagados"
                ></v-checkbox>
              </v-toolbar>
            </v-card-title>
          </template>
          <template v-slot:[`item.idc`]="{ item }">
            {{ item.idc }} ({{ item.tipo }})
          </template>
          <template v-slot:[`item.mov`]="{ item }">
            <v-icon title="Ver Movimientos" @click="verItem(item)">
              mdi-eye
            </v-icon>
          </template>
          <template v-slot:[`item.amo`]="{ item }">
            <template v-if="item.amo != ''">
              ${{ item.amo }}
            </template></template
          >
          <template v-slot:[`item.prop`]="{ item }">
            <template v-if="item.amo != ''">
              ${{ item.prop }}
            </template></template
          >
          <template v-slot:[`item.total`]="{ item }">
            <template v-if="item.amo != ''">
              ${{ (parseFloat(item.amo) + parseFloat(item.prop)).toFixed(2) }}
            </template>
          </template>
          <template v-slot:no-data>
            <br />
            <p>Sin datos disponibles</p>
          </template>
        </v-data-table>
        <template>
          <br />
          <v-toolbar dense small flat>
            <v-spacer />
            <v-icon>mdi-cash</v-icon>
            <pre>&nbsp;&nbsp;</pre>
            <h4>Consumo</h4>
            <v-divider class="mx-4" vertical></v-divider>
            <h4>$ {{ total_amo }}</h4>
          </v-toolbar>
          <v-toolbar dense small flat>
            <v-spacer />
            <v-icon>mdi-cash-plus</v-icon>
            <pre>&nbsp;&nbsp;</pre>
            <h4>Propina</h4>
            <v-divider class="mx-4" vertical></v-divider>
            <h4>$ {{ total_prop }}</h4>
          </v-toolbar>
          <v-toolbar dense small flat>
            <v-spacer />
            <v-icon>mdi-cash-multiple</v-icon>
            <pre>&nbsp;&nbsp;</pre>
            <h4>Total</h4>
            <v-divider class="mx-4" vertical></v-divider>
            <h4>$ {{ total_tot }}</h4>
          </v-toolbar>
        </template>
        <v-dialog v-model="dialogIni" hide-overlay persistent width="300">
          <v-card color="primary" dark>
            <v-card-text>
              Cargando
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="dialog500" max-width="350">
          <v-card>
            <v-card-title></v-card-title>
            <v-card-text class="text-h6">
              <h5>Problemas de conexión</h5>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="program" text @click="dialog500 = false">
                Aceptar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="dialog404" max-width="350">
          <v-card>
            <v-card-title class="headline"> Error </v-card-title>
            <v-card-text class="text-h6">
              <h5>{{ Message }}</h5>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="program" text @click="dialog404 = false">
                Aceptar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogtable" persistent max-width="1000">
          <v-card>
            <v-card-title class="headline"> {{ funtitle() }} </v-card-title>
            <v-card-text class="text-h6">
              <v-data-table
                dense
                :headers="headerstable"
                :items="itemstable"
                disable-sort
                class="elevation-0"
                hide-default-footer
                :items-per-page="-1"
              >
                <template v-slot:[`item.monto`]="{ item }">
                  ${{ item.monto }}
                </template>
                <template v-slot:[`item.hp`]="{ item }">
                  {{ funhora(item) }}
                </template>
                <template v-slot:[`item.estado_mensaje`]="{ item }">
                  <template v-if="item.estado_mensaje == '-1'">
                    {{ f1 }}
                  </template>
                  <template v-else-if="item.estado_mensaje == '0'">
                    {{ e0 }}
                  </template>
                  <template v-else-if="item.estado_mensaje == '1'">
                    {{ e1 }}
                  </template>
                  <template v-else-if="item.estado_mensaje == '2'">
                    {{ e2 }}
                  </template>
                  <template v-else-if="item.estado_mensaje == '3'">
                    {{ e3 }}
                  </template>
                  <template v-else-if="item.estado_mensaje == '4'">
                    {{ e4 }}
                  </template>
                  <template v-else-if="item.estado_mensaje == '6'">
                    {{ e6 }}
                  </template>
                  <template v-else-if="item.estado_mensaje == '21'">
                    {{ e21 }}
                  </template>
                  <template v-else-if="item.estado_mensaje == '22'">
                    {{ e22 }}
                  </template>
                  <template v-else-if="item.estado_mensaje == '23'">
                    {{ e23 }}
                  </template>
                  <template v-else-if="item.estado_mensaje == '24'">
                    {{ e24 }}
                  </template>
                  <template v-else-if="item.estado_mensaje == '31'">
                    {{ e31 }}
                  </template>
                  <template v-else-if="item.estado_mensaje == '32'">
                    {{ e32 }}
                  </template>
                  <template v-else-if="item.estado_mensaje == '33'">
                    {{ e33 }}
                  </template>
                  <template v-else-if="item.estado_mensaje == '34'">
                    {{ e34 }}
                  </template>
                  <template v-else-if="item.estado_mensaje == '61'">
                    {{ e61 }}
                  </template>
                  <template v-else-if="item.estado_mensaje == '62'">
                    {{ e62 }}
                  </template>
                  <template v-else-if="item.estado_mensaje == '63'">
                    {{ e63 }}
                  </template>
                  <template v-else>
                    Estado tipo ({{ item.estado_mensaje }})
                  </template>
                </template>
                <template v-slot:[`item.imprimir`]="{ item }">
                  <template v-if="item.estado_mensaje == '1'">
                    <v-btn icon dense large text @click="imprimir(item)">
                      <v-icon title="Imprimir">mdi-printer</v-icon>
                    </v-btn>
                    <v-btn icon dense large text @click="imagen(item)">
                      <v-icon title="Descargar imagen">mdi-image</v-icon>
                    </v-btn>
                    <v-btn icon dense large text @click="correopre(item)">
                      <v-icon title="Enviar por correo">mdi-email</v-icon>
                    </v-btn>
                  </template>
                </template>
                <template v-slot:no-data>
                  <br />
                  <p>Codigo generado sin movimientos</p>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="program" text @click="closetable"> Aceptar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="dialogAA" max-width="350">
          <v-card>
            <v-card-title></v-card-title>
            <v-card-text class="text-h6">
              <h5>Enviar un correo</h5>
              <br />
              <v-form ref="formA" onSubmit="return false;">
                <v-text-field
                  v-model="correo"
                  label="Correo"
                  outlined
                  small
                  :rules="[rules.required, rules.email]"
                  dense
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn color="program" text @click="cerrar"> Cancelar </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="program" text @click="abrir"> Aceptar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="dialogBB" max-width="350">
          <v-card>
            <v-card-title></v-card-title>
            <v-card-text class="text-h6">
              <h5>Correo enviado correctamente</h5>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="program" text @click="dialogBB = false">
                Aceptar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="dialogA" max-width="400">
          <v-card>
            <v-card-title></v-card-title>
            <v-card-text class="text-h6">
              <h5>
                Solo se puede buscar del dia de hoy o de fechas anteriores.
              </h5>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="program" text @click="dialogA = false">
                Aceptar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="dialogB" max-width="400">
          <v-card>
            <v-card-title></v-card-title>
            <v-card-text class="text-h6">
              <h5>
                Solo se puede buscar cuando la fecha inicial es menor o igual
                que la fecha final.
              </h5>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="program" text @click="dialogB = false">
                Aceptar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <div class="under" id="insertar"></div>
    </v-row>
  </div>
</template>

<style>
.style-1 {
  color: rgb(0, 0, 0);
  background-color: rgb(226, 226, 226);
}
.style-2 {
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
}
.under {
  z-index: -10;
  padding: 0;
  margin: 0;
}
.down {
  top: 20px;
}
</style>

<script>
import axios from "axios";
import moment from "moment";
import { format, parseISO } from "date-fns";
import html2canvas from "html2canvas";
import print from "print-js";
import pdf from "vue-pdf";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    print,
    VueEditor,
    pdf: pdf,
  },
  data: () => ({
    search: "",
    rules: {
      required: (value) => !!value || "Requerido",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Correo invalido";
      },
    },
    total_amo: "0.00",
    total_prop: "0.00",
    total_tot: "0.00",
    Message: "Internal Error",
    menuDateBuscaA: false,
    menuDateBuscaB: false,
    read: true,
    fecha1: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
    fecha2: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
    check: false,
    check2: false,
    dialogtable: false,
    dialogIni: false,
    dialog500: false,
    dialog404: false,
    dialogA: false,
    dialogB: false,
    dialogAA: false,
    dialogBB: false,
    correo: "",
    item: null,
    usuarios: [],
    usuario: "Todos los usuarios",
    headers: [
      {
        text: "IDC",
        align: "start",
        value: "idc",
        sortable: false
      },
      { text: "Usuario", value: "user", align: "center", sortable: false },
      { text: "Concepto", value: "des", align: "center" },
      { text: "Monto", value: "amo", align: "center", sortable: false },
      { text: "Propina", value: "prop", align: "center", sortable: false },
      { text: "Total", value: "total", align: "center", sortable: false },
      { text: "Referencia", value: "refe", align: "center", sortable: false },
      { text: "Escaneos", value: "esc", align: "center", sortable: false },
      { text: "Pagos", value: "pagos", align: "center", sortable: false },
      { text: "Fecha", value: "datos", align: "center", sortable: false },
      { text: "Ver", value: "mov", width: "10%", align: "center", sortable: false },
    ],
    headerstable: [
      {
        text: "Folio Codi",
        align: "start",
        value: "folio_codi",
      },
      { text: "Monto", value: "monto", align: "center" },
      { text: "Clave de Rastreo", value: "clave_rastreo", align: "center" },
      { text: "Estado", value: "estado_mensaje", align: "center" },
      { text: "Fecha", value: "hp", align: "center" },
      { text: "Acciones", value: "imprimir", width: "20%", align: "center" },
    ],
    items: [],
    itemstable: [],
    editedIndex: -1,
    editedItem: {
      idc: "",
      folio: "",
      des: "",
      amo: "",
      refe: "",
      datos: "",
      user: "",
      pagos: "",
      esc: "",
    },
    hecho: null,
    defaultItem: {
      idc: "",
      folio: "",
      des: "",
      amo: "",
      refe: "",
      datos: "",
      user: "",
      pagos: "",
      esc: "",
    },
    f401: "Codi Creado",
    f400: "Error (",
    f1: "Esperando Pago",
    e0: "Procesando Pago",
    e1: "Pago Realizado",
    e2: "Pago Rechazado",
    e3: "Pago No Realizado",
    e4: "Pago Pospuesto",
    e6: "Pago Devuelto",
    e21: "Pago Cancelado (21)",
    e22: "Pago Cancelado (22)",
    e23: "Pago Cancelado (23)",
    e24: "Pago Cancelado (24)",
    e31: "Pago por Devolver (31)",
    e32: "Pago por Devolver (32)",
    e33: "Pago por Devolver (33)",
    e34: "Procesando Devolución (34)",
    e61: "Procesando Devolución (61)",
    e62: "Devolución No Procesada (62)",
    e63: "Devolución No Procesada (63)",
  }),
  watch: {
    check(val) {
      val || this.closef();
    },
    check2(val) {
      val || this.cargartodo();
      !val || this.cargartodo();
    },
  },
  computed: {
    computedDateFormattedMomentjsA() {
      var localLocale = moment(this.fecha1).format("DD MMM YYYY");
      moment.locale("es");
      return this.fecha1 ? localLocale : "";
    },
    computedDateFormattedMomentjsB() {
      var localLocale = moment(this.fecha2).format("DD MMM YYYY");
      moment.locale("es");
      return this.fecha2 ? localLocale : "";
    },
  },

  methods: {
    itemRowBackground: function (item) {
      if (item.pagos == "0") {
        return "style-1";
      } else {
        return "style-2";
      }
    },

    initialize() {
      this.items = [];
      this.cargarselect();
      if (
        this.$store.state.token == "Admin" ||
        this.$store.state.token == "Root"
      ) {
        this.read = false;
      } else {
        this.read = true;
        this.usuario = this.$store.state.usuario;
      }
      this.cargartodo();
    },

    closetable() {
      this.dialogtable = false;
      this.hecho = null;
    },

    funhora(item) {
      var d = new Date(0);
      d.setUTCMilliseconds(item.hp);
      var date = moment(d).format("DD/MM/yyyy HH:mm:ss");
      return date;
    },

    verItem(item) {
      let vue = this;
      vue.dialogIni = true;
      var Param = {
        idc: item.idc,
        empresa: vue.$store.state.empresa,
      };
      vue.hecho = item;
      axios
        .post("api/Codi/Dec_Response", Param)
        .then(function (response) {
          vue.detalles(Param);
        })
        .catch(function (error) {
          console.log(error);
          vue.detalles(Param);
        });
    },

    detalles(Param) {
      let vue = this;
      axios
        .post("api/Codi/ConsultaPagosdet", Param)
        .then(function (response) {
          vue.itemstable = response.data;
          vue.dialogIni = false;
          vue.dialogtable = true;
        })
        .catch(function (error) {
          console.log(error);
          vue.dialogIni = false;
          vue.hecho = null;
        });
    },

    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },

    cargarselect() {
      var vue = this;
      vue.dialogIni = true;
      vue.usuarios = [];
      if (vue.$store.state.empresa == null || vue.$store.state.empresa == "") {
        vue.reloadPage();
        return;
      }
      var Params = {
        empresa: vue.$store.state.empresa,
      };
      axios
        .post("api/Codi/ConsultaUser", Params)
        .then(function (response) {
          vue.usuarios.push("Todos los usuarios");
          for (var i = 0; i < response.data.length; i++) {
            vue.usuarios.push(response.data[i].usuario);
          }
          vue.dialogIni = false;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          console.log(error);
        });
    },
    cargartodo() {
      var vue = this;
      vue.dialogIni = true;
      if (vue.$store.state.empresa == null || vue.$store.state.empresa == "") {
        vue.reloadPage();
        return;
      }
      if (vue.usuario == "Todos los usuarios") {
        var Params = {
          empresa: vue.$store.state.empresa,
          fecha_i: vue.fecha1,
          fecha_f: vue.fecha2,
          pagados: vue.check2,
        };
        axios
          .post("api/Codi/ConsultaHistorial", Params)
          .then(function (response) {
            vue.items = response.data;
            var amo = 0;
            var prop = 0;
            for (var i = 0; i < response.data.length; i++) {
              if (response.data[i].pagos != "0") {
                amo =
                  amo +
                  parseFloat(response.data[i].amo) *
                    parseFloat(response.data[i].pagos);
                prop =
                  prop +
                  parseFloat(response.data[i].prop) *
                    parseFloat(response.data[i].pagos);
              }
            }
            var tot = amo + prop;
            vue.total_amo = amo.toFixed(2);
            vue.total_prop = prop.toFixed(2);
            vue.total_tot = tot.toFixed(2);
            vue.dialogIni = false;
          })
          .catch(function (error) {
            vue.dialogIni = false;
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      } else {
        var Params = {
          empresa: vue.$store.state.empresa,
          fecha_i: vue.fecha1,
          fecha_f: vue.fecha2,
          usuario: vue.usuario,
          pagados: vue.check2,
        };
        axios
          .post("api/Codi/ConsultaPagos", Params)
          .then(function (response) {
            vue.items = response.data;
            var amo = 0;
            var prop = 0;
            for (var i = 0; i < response.data.length; i++) {
              if (response.data[i].pagos != "0") {
                amo =
                  amo +
                  parseFloat(response.data[i].amo) *
                    parseFloat(response.data[i].pagos);
                prop =
                  prop +
                  parseFloat(response.data[i].prop) *
                    parseFloat(response.data[i].pagos);
              }
            }
            var tot = amo + prop;
            vue.total_amo = amo.toFixed(2);
            vue.total_prop = prop.toFixed(2);
            vue.total_tot = tot.toFixed(2);
            vue.dialogIni = false;
          })
          .catch(function (error) {
            vue.dialogIni = false;
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      }
    },
    funfecha1() {
      this.menuDateBuscaA = false;
      if (!this.check) {
        this.fecha2 = this.fecha1;
      }
      var a = this.fecha1;
      var b = this.fecha2;
      let dati = new Date(
        a.substr(0, 4),
        (parseInt(a.substr(5, 2)) - 1).toString(),
        a.substr(8, 2)
      );
      let datf = new Date(
        b.substr(0, 4),
        (parseInt(b.substr(5, 2)) - 1).toString(),
        b.substr(8, 2)
      );
      let hoy = new Date();
      if (hoy < dati) {
        this.dialogA = true;
        this.fecha1 = format(parseISO(new Date().toISOString()), "yyyy-MM-dd");
        this.fecha2 = this.fecha1;
      } else if (dati > datf) {
        this.dialogB = true;
        var newDate = new Date(dati.setDate(dati.getDate()));
        this.fecha2 = format(parseISO(newDate.toISOString()), "yyyy-MM-dd");
      }
      this.cargartodo();
    },

    funfecha2() {
      this.menuDateBuscaB = false;
      var a = this.fecha1;
      var b = this.fecha2;
      let dati = new Date(
        a.substr(0, 4),
        (parseInt(a.substr(5, 2)) - 1).toString(),
        a.substr(8, 2)
      );
      let datf = new Date(
        b.substr(0, 4),
        (parseInt(b.substr(5, 2)) - 1).toString(),
        b.substr(8, 2)
      );
      let hoy = new Date();
      if (hoy < datf) {
        this.dialogA = true;
        var newDate = new Date(dati.setDate(dati.getDate()));
        this.fecha2 = format(parseISO(newDate.toISOString()), "yyyy-MM-dd");
      } else if (dati > datf) {
        this.dialogB = true;
        var newDate = new Date(dati.setDate(dati.getDate()));
        this.fecha2 = format(parseISO(newDate.toISOString()), "yyyy-MM-dd");
      }
      this.cargartodo();
    },
    closef() {
      var a = this.fecha1;
      let dati = new Date(
        a.substr(0, 4),
        (parseInt(a.substr(5, 2)) - 1).toString(),
        a.substr(8, 2)
      );
      var newDate = new Date(dati.setDate(dati.getDate()));
      this.fecha2 = format(parseISO(newDate.toISOString()), "yyyy-MM-dd");
      this.cargartodo();
    },

    funtitle() {
      if (this.hecho == null) {
        return "Historial";
      } else {
        return (
          this.hecho.des +
          " " +
          this.hecho.refe +
          " (" +
          this.hecho.datos.substr(0, 10) +
          ")"
        );
      }
    },
    imprimir(item) {
      let vue = this;
      vue.dialogIni = true;
      var Param = {
        fecha: item.hp,
        monto: item.monto,
        con: vue.hecho.des,
        refe: vue.hecho.refe,
        clave: item.clave_rastreo,
        nom: vue.$store.state.nombre,
      };
      axios
        .post("api/Codi/ImprimirComprobante", Param)
        .then(function (response) {
          vue.dialogIni = false;
          vue.print(response.data.imagen);
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },
    imagen(item) {
      let vue = this;
      vue.dialogIni = true;
      var Param = {
        fecha: item.hp,
        monto: item.monto,
        con: vue.hecho.des,
        refe: vue.hecho.refe,
        clave: item.clave_rastreo,
        nom: vue.$store.state.nombre,
      };
      axios
        .post("api/Codi/DescargarComprobante", Param)
        .then(function (response) {
          vue.dialogIni = false;
          vue.downloadcanvas(response.data.file, response.data.name);
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },
    correofun() {
      if (this.$refs.formA.validate()) {
        let vue = this;
        vue.dialogIni = true;
        var Param = {
          fecha: vue.item.hp,
          monto: vue.item.monto,
          con: vue.hecho.des,
          refe: vue.hecho.refe,
          clave: vue.item.clave_rastreo,
          nom: vue.$store.state.nombre,
          correo: vue.correo,
        };
        axios
          .post("api/Codi/EnviarComprobante", Param)
          .then(function (response) {
            vue.dialogIni = false;
            vue.dialogAA = false;
            vue.item = null;
            vue.correo = "";
            vue.dialogBB = true;
          })
          .catch(function (error) {
            vue.dialogIni = false;
            vue.dialogAA = false;
            vue.item = null;
            vue.correo = "";
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      }
    },
    correopre(item) {
      this.dialogAA = true;
      this.item = item;
    },
    print(imagen) {
      var ventana = window.open("", "PRINT");
      ventana.document.write(imagen);
      ventana.document.close();
      ventana.focus();
      setTimeout(() => {
        ventana.print();
      }, 500);
    },
    cerrar() {
      this.dialogAA = false;
      this.item = null;
      this.correo = "";
    },

    abrir() {
      this.correofun();
    },

    excel() {
      this.dialogIni = true;
      this.exportData();
      this.dialogIni = false;
    },

    exportData() {
      var rows = [];
      rows.push([
        this.headers[0].text,
        this.headers[1].text,
        this.headers[2].text,
        this.headers[3].text,
        this.headers[4].text,
        this.headers[5].text,
        this.headers[6].text,
        this.headers[7].text,
        this.headers[8].text,
        this.headers[9].text,
      ]);
      for (var i = 0; i < this.items.length; i++) {
        var column1 = this.items[i].idc;
        var column2 = this.items[i].user;
        var column3 = this.items[i].des;
        var column4 = "$" + parseFloat(this.items[i].amo).toFixed(2);
        var column5 = "$" + parseFloat(this.items[i].prop).toFixed(2);
        var column6 =
          "$" +
          (
            parseFloat(this.items[i].prop) + parseFloat(this.items[i].amo)
          ).toFixed(2);
        var column7 = this.items[i].refe;
        var column8 = this.items[i].esc;
        var column9 = this.items[i].pagos;
        var column10 = this.items[i].datos + " h";

        rows.push([
          column1,
          column2,
          column3,
          column4,
          column5,
          column6,
          column7,
          column8,
          column9,
          column10,
        ]);
      }
      rows.push([""]);
      rows.push(["Consumo", "$" + this.total_amo]);
      rows.push(["Propina", "$" + this.total_prop]);
      rows.push(["Total", "$" + this.total_tot]);
      var csvContent = "data:text/csv;charset=utf-8,";
      rows.forEach(function (rowArray) {
        var row = rowArray.join(",");
        csvContent += row + "\r\n";
      });

      var fecha = moment(new Date()).format("yyyy_MM_DD HH.mm.ss");
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "Historial (" + fecha + ").csv");
      document.body.appendChild(link);
      link.click();
    },

    downloadcanvas(file, name) {
      document.getElementById("insertar").innerHTML = "";
      var doc = document.getElementById("insertar");
      doc.insertAdjacentHTML("beforeend", file);

      html2canvas(document.getElementById("imagen"))
        .then((canvas) => {
          const linkSource = canvas.toDataURL("image/png");
          const downloadLink = document.createElement("a");
          const fileName = name;

          document.getElementById("insertar").innerHTML = "";

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.$store.dispatch("autologinA");
    this.initialize();
  },
  created() {
    if (true) {
      var localLocale = moment(this.fecha1).format("DD MMM YYYY");
      moment.locale("es");
    }
    if (true) {
      var localLocale = moment(this.fecha2).format("DD MMM YYYY");
      moment.locale("es");
    }
  },
};
</script>